@import 'core';

.AppCore {
    .Button {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        border: 1px solid transparent;
        border-radius: var(--corner-radius-md);
        transition:
            color 0.15s ease-in-out,
            background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out;
        text-decoration: none;
        box-shadow: none;

        &.fullWidth {
            width: 100%;
        }

        &.rounded {
            border-radius: var(--corner-radius-round);
        }

        .typography {
            // TODO: remove once is removed from typography
            margin-bottom: 0px !important;
            color: inherit;
            white-space: nowrap;
            text-decoration: none;
        }

        &.iconButton {
            padding: inherit;
            &.plain {
                background-color: transparent;
                border-bottom-color: transparent;

                &.active,
                &:focus,
                &:hover {
                    background-color: transparent;
                    opacity: 0.8;
                }
                &.xxs {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
            .IconContainer {
                &.startIcon {
                    margin-right: 0 !important;
                }
            }
            &.xxs {
                padding-left: 0px;
                padding-right: 0px;
            }
            &.xs {
                padding-left: 3px;
                padding-right: 3px;
            }
            &.s {
                padding-left: 10px;
                padding-right: 10px;
            }
            &.l {
                padding-left: 10px;
                padding-right: 10px;
            }
            &.m {
                padding: 11px;
            }
        }

        .IconContainer {
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &.startIcon {
                margin-right: 8px;
            }
            &.endIcon {
                margin-left: 8px;
            }

            &.xl {
                width: 36px;
                height: 36px;
            }
            &.l {
                width: 26px;
                height: 26px;
            }
            &.m {
                width: 24px;
                height: 24px;
            }
            &.s {
                width: 24px;
                height: 24px;
            }
            &.xs {
                width: 22px;
                height: 22px;
            }
            &.xxs {
                width: 20px;
                height: 20px;
            }
        }

        &:hover,
        &:focus {
            text-decoration: none;
            outline: none;
        }

        &.disabled,
        &:disabled {
            opacity: 0.6;
            box-shadow: none;
            pointer-events: none;
        }

        &:not(:disabled):not(.disabled) {
            cursor: pointer;
        }

        &.xl {
            padding: 23px 32px;
        }

        &.l {
            padding: 13px 22px;
        }
        &.m {
            padding: 11px 16px;
        }
        &.s {
            padding: 7px 20px;
        }
        &.xs {
            padding: 3px 8px;
            line-height: 24px;

            > * {
                line-height: inherit;
            }
        }
        &.xxs {
            padding: 0 10px;
        }

        &.primary {
            color: var(--button-primary-content);
            background-color: var(--button-primary-fill);

            &.active,
            &:focus,
            &:hover {
                background-color: var(--button-primary-fill-hover);
            }

            &.disabled,
            &:disabled {
                opacity: 0.35;
                background-color: var(--button-primary-fill);
            }
        }

        &.secondary {
            color: var(--button-secondary-content);
            background-color: var(--button-secondary-fill);

            &.active,
            &:focus,
            &:hover {
                background-color: var(--button-secondary-fill-hover);
                border-color: var(--button-secondary-fill);
            }

            &.disabled,
            &:disabled {
                opacity: 0.35;
                background-color: var(--button-secondary-fill);
            }
        }

        &.tertiary {
            color: var(--button-tertiary-content);
            border-color: var(--button-tertiary-stroke);
            background-color: var(--button-tertiary-fill);

            &.active,
            &:focus,
            &:hover {
                background-color: var(--button-tertiary-fill-hover);
                border-color: var(--button-tertiary-stroke);
            }

            &.disabled,
            &:disabled {
                opacity: 0.35;
                background-color: transparent;
            }
        }

        &.danger {
            color: var(--button-destructive-content);
            background-color: var(--button-destructive-fill);

            &.active,
            &:focus,
            &:hover {
                background-color: var(--button-destructive-fill-hover);
            }

            &.disabled,
            &:disabled {
                opacity: 0.35;
                background-color: var(--button-destructive-fill);
            }
        }
        &.danger {
            color: var(--button-destructive-content);
            background-color: var(--button-destructive-fill);

            &.active,
            &:focus,
            &:hover {
                background-color: var(--button-destructive-fill-hover);
            }

            &.disabled,
            &:disabled {
                opacity: 0.35;
                background-color: var(--button-destructive-fill);
            }
        }

        &.success {
            color: var(--button-success-content);
            background-color: var(--button-success-fill);

            &.active,
            &:focus,
            &:hover {
                background-color: var(--button-success-fill-hover);
            }

            &.disabled,
            &:disabled {
                opacity: 0.35;
                background-color: var(--button-success-fill);
            }
        }

        &.plain {
            color: var(--button-tertiary-content);
            background-color: transparent;

            &.active,
            &:focus,
            &:hover {
                background-color: var(--button-text-secondary-fill-hover);
            }

            &.disabled,
            &:disabled {
                opacity: 0.35;
            }
        }
        &.plainPrimary {
            color: var(--button-text-primary-content);
            background-color: transparent;

            &.active,
            &:focus,
            &:hover {
                background-color: var(--button-text-primary-fill-hover);
            }

            &.disabled,
            &:disabled {
                opacity: 0.35;
            }
        }

        &.plainPrimaryOutlined {
            color: var(--button-plain-primary-outlined-color);
            border-color: var(--button-plain-primary-outlined-stroke);
            background-color: var(--button-plain-primary-outlined-fill);

            &.active,
            &:focus,
            &:hover {
                color: var(--button-text-primary-content);
                border-color: var(--button-primary-fill);
                background-color: var(--button-text-primary-fill-hover);
            }

            &.disabled,
            &:disabled {
                opacity: 0.35;
            }
        }

        .IconContainer .Icon:not(#hack) {
            color: inherit;
            transition: color 0.15s ease-in-out;
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
        }
    }

    a.Button.disabled,
    fieldset:disabled a.Button {
        pointer-events: none;
    }
}

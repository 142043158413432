@import 'core';

.AppCore {
    .Dropdown {
        position: relative;

        .DropdownHeader {
            font-size: inherit;
        }

        .DropdownHeaderWithArrow {
            &:after {
                content: '';
                display: block;
                padding: 0;
                position: absolute;
                top: 50%;
                margin-top: -1px;
                right: 3px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 4px 3.5px 0 3.5px;
                border-color: $grey-dark-2 transparent transparent transparent;
            }
        }

        .DropdownHeaderDisabled {
            pointer-events: none;
        }

        &.DropdownOpened {
            color: inherit;
        }

        &.DropdownDisabledFocus:focus,
        &.DropdownDisabledFocus .DropdownHeader:focus {
            outline: none;
        }
    }

    .DropdownBody {
        width: 100%;

        .DropdownCloseAction,
        .DropdownBg {
            display: none;
        }

        @include media-breakpoint-down(none) {
            position: relative;

            > * {
                position: relative;
                z-index: 2;
            }

            .DropdownBg {
                display: block;
                z-index: 1;
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: $black;
                opacity: 0.3;
            }

            .DropdownCloseAction {
                display: flex;
                position: absolute;
                top: -6px;
                right: -14px;
                z-index: 3;

                align-items: center;
                justify-content: center;

                width: 32px;
                height: 32px;
                border-radius: 50%;
                border-bottom: none;
                background: $primary-blue;

                .Icon {
                    width: 19px;
                    height: 19px;
                    color: $white;
                }
            }
        }
    }

    .Portal-FixedToRight {
        position: fixed !important;
        top: 0 !important;
        bottom: 0 !important;
        right: 0 !important;
        background: rgba(0, 0, 0, 0.5);
        .DropdownBody {
            width: fit-content;
            margin-left: auto;
        }
    }

    .Portal-FixedToLeft {
        position: fixed !important;
        top: 0 !important;
        bottom: 0 !important;
        right: auto !important;
        left: 0 !important;
        width: 420px !important;
        background-color: $white;
        padding: 0 0 20px;
        box-shadow:
            0 1px 3px rgba(0, 0, 0, 0.12),
            0 1px 2px rgba(0, 0, 0, 0.24);
    }
    @include media-breakpoint-down(none) {
        .full-screen-sm {
            > .DropdownBody .Menu,
            > .DropdownBody .MenuWithSearch {
                max-height: 80vh !important;
                overflow: auto;
            }
        }
    }
}

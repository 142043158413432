@import 'core';

.AppCore {
    .Menu {
        &.WithMaxHeight {
            overflow-y: auto;
        }
    }

    .Card > .Menu {
        padding: 0;
    }

    .MenuItem {
        > * {
            display: block;
            padding: 14px 18px 16px;
            cursor: pointer;
            font-size: 18px;
            line-height: 22px;
            text-decoration: none;
        }

        .Icon {
            width: 18px;
            height: 18px;
            vertical-align: top;
            margin-top: 2px;

            &.Circle {
                line-height: 18px;
            }
        }
    }

    a.MenuItem,
    .MenuItem > a {
        border-bottom: none;
    }

    .MenuItemWrapper {
        display: block;
        position: relative;
        border-bottom: none;
        outline: none;

        &.SelectedMenuItem > .MenuItem > *,
        &:hover > .MenuItem > *,
        &:focus > .MenuItem > *,
        &:focus-within > .MenuItem > * {
            color: $primary-blue;
            text-decoration: none;
            background-color: $grey-light-3;
        }

        > .Card {
            display: none;

            @include media-breakpoint-up(mobile) {
                position: absolute;
                z-index: 1;
                width: 100%;
                left: 100%;
                top: 0;
                margin-left: -2px;
            }
        }

        &:hover > .Card,
        &:focus > .Card,
        &:focus-within > .Card {
            display: block;
        }
    }

    .MenuItemWrapperWithChildren {
        .MenuItem > * {
            padding-right: 45px;
        }

        > .Icon {
            position: absolute;
            top: 15px;
            right: 12px;

            width: 23px;
            height: 23px;
        }

        @include media-breakpoint-down(none) {
            > .Card {
                margin: 30px 0;
            }

            > .Icon {
                transform: rotate(-90deg);
            }
        }
    }

    .MenuItemDisabled {
        opacity: 0.6;
        pointer-events: none;
    }

    .Menu.LeftSubMenus {
        @include media-breakpoint-up(mobile) {
            .MenuItemWrapperWithChildren {
                > .Card {
                    left: auto;
                    right: 100%;
                }

                > .MenuItem {
                    > * {
                        padding-right: 18px;
                        padding-left: 30px;
                    }
                }

                > .Icon {
                    right: auto;
                    left: 5px;
                }
            }
        }
    }

    &.DarkMode {
        .MenuItem {
            color: $white;
        }

        .MenuItemWrapper {
            &.SelectedMenuItem > .MenuItem > *,
            &:hover > .MenuItem > *,
            &:focus > .MenuItem > *,
            &:focus-within > .MenuItem > * {
                color: $white;
                background-color: $grey-dark-4;
            }
        }
    }
}

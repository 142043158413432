:root .AppCore.DarkMode {
    --background-base: var(--neutral-900);
    --background-primary-light: var(--neutral-900);
    --background-elevated-light: var(--neutral-950);
    --background-hover: var(--neutral-700);
    --fill-primary: var(--neutral-100);
    --fill-secondary: var(--neutral-300);
    --fill-tertiary: var(--neutral-700);
    --fill-accent: var(--accent-400);
    --fill-accent-weak: var(--accent-100);
    --fill-error: var(--error-400);
    --fill-error-weak: var(--error-100);
    --fill-success: var(--success-400);
    --fill-success-weak: var(--success-100);
    --fill-info: var(--info-400);
    --fill-info-weak: var(--info-100);
    --fill-warning: var(--warning-300);
    --fill-warning-weak: var(--warning-100);
    --fill-s1: var(--supplementary-1-400);
    --fill-s1-weak: var(--supplementary-1-100);
    --fill-s2: var(--supplementary-2-300);
    --fill-s2-weak: var(--supplementary-2-100);
    --fill-s3: var(--supplementary-3-400);
    --fill-s3-weak: var(--supplementary-3-100);
    --fill-s4: var(--supplementary-4-400);
    --fill-s4-weak: var(--supplementary-4-100);
    --fill-s5: var(--supplementary-5-300);
    --fill-s5-weak: var(--supplementary-5-100);
    --content-primary: var(--neutral-100);
    --content-secondary: var(--neutral-300);
    --content-accent: var(--accent-400);
    --content-inverse: var(--neutral-900);
    --content-inverse-secondary: var(--neutral-700);
    --content-error: var(--error-400);
    --content-success: var(--success-400);
    --content-warning: var(--warning-400);
    --content-info: var(--info-300);
    --content-s1-on-weak-fill: var(--supplementary-1-900);
    --content-s2-on-weak-fill: var(--supplementary-2-900);
    --content-s3-on-weak-fill: var(--supplementary-3-900);
    --content-s4-on-weak-fill: var(--supplementary-4-900);
    --content-s5-on-weak-fill: var(--supplementary-5-900);
    --stroke-default: var(--neutral-750);
    --stroke-strong: var(--neutral-700);
    --stroke-accent: var(--accent-300);
    --stroke-inverse-default: var(--neutral-350);
    --stroke-inverse-strong: var(--neutral-300);
    --stroke-error-on-weak-fill: var(--error-400);
    --stroke-success-on-weak-fill: var(--success-400);
    --stroke-warning-on-weak-fill: var(--warning-400);
    --stroke-info-on-weak-fill: var(--info-400);
    --stroke-s1-on-weak-fill: var(--supplementary-1-400);
    --stroke-s2-on-weak-fill: var(--supplementary-2-400);
    --stroke-s3-on-weak-fill: var(--supplementary-3-400);
    --stroke-s4-on-weak-fill: var(--supplementary-4-400);
    --stroke-s5-on-weak-fill: var(--supplementary-5-400);
    --content-tertiary: var(--neutral-400);
    --content-inverse-tertiary: var(--neutral-500);
    --content-error-on-weak-fill: var(--error-900);
    --content-success-on-weak-fill: var(--success-900);
    --content-warning-on-weak-fill: var(--warning-900);
    --content-info-on-weak-fill: var(--info-900);
    --fill-elevated-primary: var(--neutral-850);
    --background-inverse: var(--neutral-200);
    --stroke-error: var(--error-500);
    --stroke-success: var(--success-500);
    --stroke-accent-on-weak-fill: var(--accent-500);
    --content-accent-on-weak-fill: var(--accent-900);
    --fill-elevated-secondary: var(--neutral-750);
    --fill-base-secondary: var(--neutral-850);
    --fill-base: var(--neutral-900);
    --content-quaternary: var(--neutral-600);
    --fill-accent-secondary: var(--accent-600);
    --fill-accent-tertiary: var(--accent-700);
    --content-accent-secondary: var(--accent-500);
    --content-accent-tertiary: var(--accent-700);
    --fill-elevated-tertiary: var(--neutral-950);
    --content-s1: var(--supplementary-1-400);
    --content-s2: var(--supplementary-2-400);
    --content-s3: var(--supplementary-3-400);
    --content-s4: var(--supplementary-4-400);
    --content-s5: var(--supplementary-5-400);
    --content-s1-weak: var(--supplementary-1-600);
    --content-s1-strong: var(--supplementary-1-300);
    --content-s2-weak: var(--supplementary-2-600);
    --content-s2-strong: var(--supplementary-2-300);
    --content-s3-weak: var(--supplementary-3-700);
    --content-s3-strong: var(--supplementary-3-300);
    --content-s4-weak: var(--supplementary-4-700);
    --content-s4-strong: var(--supplementary-4-300);
    --content-s5-weak: var(--supplementary-5-700);
    --content-s5-strong: var(--supplementary-5-300);
    --button-tertiary-fill: var(--neutral-100);
}

@import 'core';

.AppCore {
    .CircleOutlineIcon {
        position: relative;

        > .Icon:not(#hack) {
            width: 22px;
            height: 22px;
        }

        > .InsideCircle {
            position: absolute;
            top: 1px;
            left: -1px;
            width: 24px;
            text-align: center;
            font-size: 12px;
            line-height: 20px;
        }
    }
}

@import 'core';

.AppCore {
    .Tabs {
        .TabHeaders {
            padding: 0;
            margin-bottom: 14px;
            border: 0;
            box-shadow: none;
            border-bottom: 1px solid var(--stroke-strong);
            border-radius: 0;
            background-color: transparent;
        }

        .TabHeader {
            display: inline-block;
            padding: 8px 0;
            margin-right: 36px;
            border-width: 3px;
            border-color: transparent;
            outline: none;

            &:first-child {
                margin-left: 2px;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .TabBody {
            background: transparent;
        }

        .FullWidthHeaders {
            display: flex;
        }
    }

    .ColorfulTabs {
        .TabHeaders {
            display: flex;
            padding: 0;
            margin-bottom: 0;
            border: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .ColorfulTabHeaderBorder {
                display: none;
                position: absolute;
                top: -1px;
                left: -1px;
                right: -1px;
                bottom: -1px;
                border-width: 2px;
                border-style: solid;
                border-color: inherit;
                border-bottom: none;
            }

            .ColorfulTabHeaderFill {
                display: none;
                position: absolute;
                left: 1px;
                right: 1px;
                bottom: -3px;
                height: 2px;
            }

            .TabHeader {
                position: relative;
                z-index: 1;
                flex: 1;
                margin: 0 0 0 -1px;
                text-align: center;
                border: 1px solid $grey-light-1;
                border-bottom-color: transparent !important;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;

                > span {
                    border-width: 0;
                    border-color: inherit;
                }

                &:first-child {
                    border-top-left-radius: 4px;
                    margin-left: 0;

                    .ColorfulTabHeaderBorder {
                        border-top-left-radius: 4px;
                    }
                }

                &:last-child {
                    border-top-right-radius: 4px;

                    .ColorfulTabHeaderBorder {
                        border-top-right-radius: 4px;
                    }
                }

                &.active,
                &:hover,
                &:focus {
                    color: $grey-dark-1;
                }

                &.active,
                &:focus {
                    z-index: 2;

                    .ColorfulTabHeaderBorder {
                        display: block;
                    }

                    .ColorfulTabHeaderFill {
                        display: block;
                    }
                }
            }
        }
        .TabBody {
            border: 2px solid transparent;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            box-shadow: $eleveted-surface-shadow;
        }
    }

    .NeutralTabHeader {
        color: var(--content-secondary);
        letter-spacing: 0.33px;

        &.active,
        &:focus {
            color: var(--content-primary);
            border-bottom-color: var(--stroke-inverse-strong);
            font-weight: 600;
            letter-spacing: 0;

            .Icon {
                color: var(--content-primary);
            }
        }
    }

    .AccentTabHeader {
        color: var(--content-primary);
        letter-spacing: 0.33px;

        &.active,
        &:focus {
            color: var(--content-accent);
            border-bottom-color: var(--stroke-accent);
            font-weight: 600;
            letter-spacing: 0;

            .Icon {
                color: var(--content-accent);
            }
        }
    }

    .FullWidthTabHeader {
        flex: 1;
        text-align: center;
        margin-right: 0;
    }
}

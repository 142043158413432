@import 'core';

.AppCore {
    .DayColumn {
        display: flex;
        flex-direction: column;
        border-left: 1px solid $grey-light-1;
        .HourCell {
            border-bottom: 2px solid $grey-light-1;

            position: relative;
            flex-shrink: 0;
            padding: 1px;
            &:last-child {
                border-bottom: none;
            }
        }

        .Slot {
            border-bottom: 1px solid $grey-light-2;
            position: relative;
            width: 100%;
            &:last-child {
                border-bottom: none;
            }
        }
    }
}

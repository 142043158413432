@import 'core';

.AppCore {
    .Breadcrumbs {
        padding: 0 8px;
        margin-left: -16px;

        .Crumb {
            display: inline-block;

            > * {
                color: var(--content-tertiary);
            }

            .typography {
                padding: 4px 8px;

                > a {
                    color: var(--content-tertiary);
                    text-decoration: none;
                    border-bottom: none;
                }
            }

            .Separator {
                display: inline-block;
                vertical-align: top;
                color: var(--content-tertiary);
                margin: 0 3px;
            }

            &:last-child .Separator {
                display: none;
            }

            &:hover .typography > a,
            &:focus .typography > a,
            &:active .typography > a {
                color: var(--content-accent);
            }
        }
    }

    &.DarkMode .Breadcrumbs .Crumb {
        > * {
            color: $white;
        }

        .Separator {
            color: $white;
        }
    }
}

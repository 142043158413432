.AppCore {
    .NotificationIndicator {
        margin-left: auto;
        .Icon {
            color: var(--content-quaternary);
        }
        &.UnreadNotification {
            .Icon {
                color: var(--content-error);
            }
        }
    }
}

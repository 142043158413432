@import 'core';

.AppCore {
    .DataTableFilterPill {
        display: block;
        padding: 7px 10px;
        margin-right: 8px;
        border-bottom: none;
        border-radius: 4px;
        background: $violet-light;

        > .Icon {
            display: inline-block;
            vertical-align: top;
            width: 16px;
            height: 16px;
            margin-top: 6px;
            margin-left: 6px;
        }
    }
}

@import 'core';

.AppCore {
    .DataTableContainer {
        position: relative;
    }

    .Table .Row.DataTableGridContainer {
        padding: 0;

        > .Col {
            padding: 0;
        }
    }

    .DataTable {
        .FilterSortContainer {
            display: flex;
        }
        > .Row > .Col {
            white-space: nowrap;
        }

        .SortGizmo {
            border-bottom: none;

            .Icon {
                color: $grey-dark-2;
                width: 20px;
                height: 20px;
                line-height: 20px;
            }
        }

        .SortGizmoActiveIcon {
            display: none;
        }

        .SortGizmoActive {
            .SortGizmoActiveIcon {
                display: inline-block;
                vertical-align: top;
                margin-left: -20px;
                margin-right: 10px;
                width: 10px;
                overflow: hidden;

                .Icon {
                    color: $grey-dark-1;
                }
            }

            &.descending {
                .SortGizmoActiveIcon {
                    margin-left: -10px;
                    margin-right: 0;

                    .Icon {
                        margin-left: -10px;
                    }
                }
            }
        }

        .FilterGizmo {
            display: inline-block;
            margin-left: 4px;
            .Button {
                padding: 0;
                .IconContainer {
                    padding-top: 5px;
                    padding-bottom: 5px;
                    .Icon {
                        align-items: initial;
                    }
                    svg {
                        width: initial;
                    }
                }
            }
        }
    }

    .ToggleDataTableViewMode {
        padding: 4px 10px;
    }

    .DataTableIsLoadingOverlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        .Preloader {
            opacity: 0.7;
            border-radius: 4px;
        }
    }

    .TableFlexHeader {
        display: flex;
        align-items: flex-start;
        > * {
            flex-basis: content;
        }
    }

    .DataTableTopActionsDropdown {
        .Input {
            padding-top: 7px;
            padding-bottom: 7px;

            transition:
                color 0.15s ease-in-out,
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out;

            &:hover {
                border-color: $grey-dark-2;
            }
        }

        .SelectHeaderLabel {
            font-size: 17px;
            line-height: 24px;
            font-weight: 500;
            color: $grey-dark-1;

            .Icon {
                vertical-align: top;
                margin-right: 6px;
                margin-top: 0;
                width: 24px;
                height: 24px;
                line-height: 24px;
                color: $grey-dark-2;
            }
        }
    }

    .DataTableTopActionsToggle {
        padding: 7px 12px;
    }

    .DataTableFilterGizmoBody {
        width: 300px;

        .Dropdown {
            .SelectHeaderLabel {
                white-space: normal;
            }
        }

        .MenuItemWrapper {
            width: 255px;
        }
    }

    .DataTablePaginationRow {
        justify-content: flex-end;

        @include media-breakpoint-down(none) {
            justify-content: center;
        }
    }

    &.DarkMode {
        .DataTableTopActionsDropdown {
            .Input {
                > span > .Icon {
                    color: $white !important;
                }
            }
            .InputBaseLabel {
                color: $white;
            }
            .SelectHeaderLabel {
                color: $white;

                .Icon {
                    color: $white;
                }
            }
        }
    }

    @include media-breakpoint-down(none) {
        .DataTableFilterGizmoBody {
            width: 100%;

            .MenuItemWrapper {
                width: auto;
            }
        }

        .full-screen-sm {
            > .DropdownBody.DataTableFilterGizmoBody .Menu,
            > .DropdownBody.DataTableFilterGizmoBody .MenuWithSearch {
                max-height: 70vh !important;
            }
        }
    }
}

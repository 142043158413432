@import '../core/Utils';

.AppCore {
    @mixin make-grid-gutters($gutter) {
        &.gutter-#{strip-unit($gutter)} {
            > .Row {
                > .Col {
                    padding-right: calc($gutter / 2);
                    padding-left: calc($gutter / 2);
                }
            }
        }
    }

    @mixin make-grid-cols($columns) {
        &.cols-#{$columns} {
            > .Row {
                @for $i from 1 through $columns {
                    > .Col.col-#{$i} {
                        flex: 0 0 calc($i / $columns * 100%);
                        max-width: calc($i / $columns * 100%);
                    }

                    > .Col.order-#{$i} {
                        order: $i;
                    }
                }

                @for $i from 1 through $columns {
                    @include media-breakpoint-down(none) {
                        > .Col.col-sm-#{$i} {
                            flex: 0 0 calc($i / $columns * 100%);
                            max-width: calc($i / $columns * 100%);
                        }
                    }
                }

                @for $i from 1 through ($columns - 1) {
                    > .Col.offset-#{$i} {
                        margin-left: calc($i / $columns * 100%);
                    }
                }

                > .Col.col-0 {
                    flex: 0 0 0;
                    max-width: 0;
                    padding: 0;
                    display: none;
                }

                @include media-breakpoint-down(none) {
                    > .Col.col-sm-0 {
                        flex: 0 0 0;
                        max-width: 0;
                        padding: 0;
                        display: none;
                    }
                }
            }
        }
    }

    .Grid {
        > .Row {
            flex-wrap: wrap;

            > .Col {
                position: relative;
                width: 100%;
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }
        }

        @include make-grid-gutters(14px);
        @include make-grid-gutters(16px);
        @include make-grid-gutters(19px);
        @include make-grid-gutters(24px);
        @include make-grid-gutters(30px);

        @include make-grid-cols(2);
        @include make-grid-cols(3);
        @include make-grid-cols(4);
        @include make-grid-cols(5);
        @include make-grid-cols(6);
        @include make-grid-cols(7);
        @include make-grid-cols(8);
        @include make-grid-cols(9);
        @include make-grid-cols(10);
        @include make-grid-cols(11);
        @include make-grid-cols(12);
    }
}

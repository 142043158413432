@import 'core';

.AppCore {
    .SliderWrapInner {
        position: relative;

        &.WithArrows {
            padding-right: 38px;
        }

        > .SliderArrows {
            width: 20px;
            position: absolute;
            top: 5px;
            right: 0;

            .Button {
                padding: 0;
                position: absolute;
                left: 0;

                &:first-child {
                    top: -13px;
                }

                &:last-child {
                    top: 7px;
                }

                &:disabled {
                    pointer-events: auto;
                }
            }
        }
    }

    .Slider {
        position: relative;
        display: block;
        height: 2px;
        padding: 13px 0;
        z-index: 1;
    }

    .SliderWithMarkLabels {
        padding-bottom: 30px;
    }

    .SliderRail {
        position: absolute;
        top: 11px;
        left: 0;
        z-index: 10;
        display: block;
        width: 100%;
        height: 2px;
        background: var(--fill-tertiary);
        opacity: 0.4;
        cursor: pointer;
    }

    .SliderRailMark {
        position: absolute;
        display: block;
        z-index: 25;
        width: 2px;
        height: 6px;
        margin-top: -4px;
        margin-left: -1px;
        background: var(--content-accent);
    }

    .SliderRailMarkAfterKnob {
        background: var(--fill-tertiary);
    }

    .SliderWithMarkLabels .SliderRailMark > span {
        position: absolute;
        top: 8px;
        white-space: nowrap;
        transform: translateX(-50%);
    }

    .SliderProgress {
        position: absolute;
        display: block;
        z-index: 20;
        width: 0;
        height: 2px;
        margin-top: -2px;
        background: var(--content-accent-secondary);
    }

    .SliderKnob {
        position: absolute;
        z-index: 30;
        display: block;
        width: 12px;
        height: 12px;
        margin-left: -6px;
        margin-top: -7px;
        background: var(--content-accent-secondary);
        border-radius: 50%;
        border-bottom: none;
        touch-action: none;
        cursor: pointer;
    }

    .SliderKnob:hover,
    .SliderKnobSliding {
        width: 16px;
        height: 16px;
        margin-left: -8px;
        margin-top: -9px;
    }

    .SliderKnob:focus:not(.SliderKnobSliding):not(:hover) {
        &:after {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            position: absolute;
            top: -4px;
            left: -4px;
            border: 10px solid var(--content-accent-secondary);
            border-radius: 50%;
            opacity: 0.3;
        }
    }

    .SliderWrap {
        position: relative;
        outline: none;

        .InputBaseWrapperInner {
            box-shadow: none;
        }

        &.InputBaseDisabledWrapper {
            .SliderRail {
                background: var(--fill-tertiary);
                cursor: default;
            }

            .SliderProgress {
                background: var(--content-quaternary);
                opacity: 0.4;
            }

            .SliderRailMarkAfterKnob {
                background: var(--fill-tertiary);
                opacity: 0.4;
            }

            .SliderWithMarkLabels .SliderRailMark > span {
                color: var(--content-secondary);
            }

            .SliderKnob {
                background: var(--content-quaternary);
                pointer-events: none;
            }
        }

        &.InputBaseWithErrorWrapper {
            .SliderRail {
                background: var(--fill-tertiary);
            }

            .SliderProgress {
                background: var(--content-error);
            }

            .SliderRailMarkAfterKnob {
                background: var(--content-error);
            }

            .SliderKnob {
                background: var(--content-error);
            }

            &.InputBaseDisabledWrapper {
                .SliderRail {
                    opacity: 0.1;
                }

                .SliderProgress {
                    opacity: 0.3;
                }

                .SliderKnob {
                    opacity: 0.9;
                }
            }
        }
    }

    .SliderRailDisabled {
        background: var(--fill-tertiary);
    }

    .SliderProgressDisabled {
        background: var(--content-quaternary);
        opacity: 0.4;
    }

    .SliderRailMarkOutsideSelectableRange {
        background: var(--content-quaternary);
        opacity: 0.4;
    }

    .SliderWithMarkLabels .SliderRailMarkOutsideSelectableRange > span {
        color: var(--fill-tertiary);
    }
}

@import 'core';

.AppCore {
    .CalendarDayContainer {
        display: flex;
        align-items: center;
        color: $grey-dark-1;
    }
    .CalendarDayLabel {
        margin-left: 4px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $grey-dark-2;
        &.hideDayName {
            color: $grey-dark-1;
            font-weight: 500;
        }
        &.isToday {
            background-color: $primary-blue-light;
            color: white;
        }
        &.clickable {
            cursor: pointer;
            &:hover {
                background-color: $grey-light-1;
            }
        }
    }
}

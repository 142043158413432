:root {
    --elevated-surface-x: var(--primitive-sizes-0);
    --elevated-surface-y: var(--primitive-sizes-2);
    --elevated-surface-blur: var(--primitive-sizes-8);
    --elevated-surface-spread: var(--primitive-sizes-0);
    --elevated-surface-color: var(--neutral-200);
    --side-nav-x: var(--primitive-sizes-12);
    --side-nav-y: var(--primitive-sizes-0);
    --side-nav-blur: var(--primitive-sizes-8);
    --side-nav-spread: var(--primitive-sizes-0);
    --side-nav-color: var(--neutral-250);
    --table-header-x: var(--primitive-sizes-0);
    --table-header-y: var(--primitive-sizes-4);
    --table-header-blur: var(--primitive-sizes-12);
    --table-header-spread: var(--primitive-sizes-0);
    --table-header-color: rgba(207, 216, 223, 0.5);
    --pop-up-overlay-x: var(--primitive-sizes-2);
    --pop-up-overlay-y: var(--primitive-sizes-4);
    --pop-up-overlay-blur: var(--primitive-sizes-16);
    --pop-up-overlay-spread: var(--primitive-sizes-0);
    --pop-up-overlay-color: var(--neutral-450);
}

:root {
    --background-base: var(--neutral-200);
    --background-primary-light: var(--neutral-60);
    --background-elevated-light: var(--neutral-50);
    --background-hover: var(--neutral-270);
    --fill-primary: var(--neutral-900);
    --fill-secondary: var(--neutral-800);
    --fill-tertiary: var(--neutral-300);
    --fill-accent: var(--accent-600);
    --fill-accent-weak: var(--accent-100);
    --fill-error: var(--error-600);
    --fill-error-weak: var(--error-100);
    --fill-success: var(--success-600);
    --fill-success-weak: var(--success-100);
    --fill-info: var(--info-500);
    --fill-info-weak: var(--info-100);
    --fill-warning: var(--warning-400);
    --fill-warning-weak: var(--warning-100);
    --fill-s1: var(--supplementary-1-500);
    --fill-s1-weak: var(--supplementary-1-100);
    --fill-s2: var(--supplementary-2-500);
    --fill-s2-weak: var(--supplementary-2-100);
    --fill-s3: var(--supplementary-3-600);
    --fill-s3-weak: var(--supplementary-3-100);
    --fill-s4: var(--supplementary-4-500);
    --fill-s4-weak: var(--supplementary-4-100);
    --fill-s5: var(--supplementary-5-500);
    --fill-s5-weak: var(--supplementary-5-100);
    --content-primary: var(--neutral-900);
    --content-secondary: var(--neutral-700);
    --content-accent: var(--accent-700);
    --content-inverse: var(--neutral-100);
    --content-inverse-secondary: var(--neutral-300);
    --content-error: var(--error-700);
    --content-success: var(--success-700);
    --content-warning: var(--warning-700);
    --content-info: var(--info-700);
    --content-s1-on-weak-fill: var(--supplementary-1-900);
    --content-s2-on-weak-fill: var(--supplementary-2-900);
    --content-s3-on-weak-fill: var(--supplementary-3-900);
    --content-s4-on-weak-fill: var(--supplementary-4-900);
    --content-s5-on-weak-fill: var(--supplementary-5-900);
    --stroke-default: var(--neutral-350);
    --stroke-strong: var(--neutral-300);
    --stroke-accent: var(--accent-600);
    --stroke-inverse-default: var(--neutral-750);
    --stroke-inverse-strong: var(--neutral-900);
    --stroke-error-on-weak-fill: var(--error-300);
    --stroke-success-on-weak-fill: var(--success-400);
    --stroke-warning-on-weak-fill: var(--warning-300);
    --stroke-info-on-weak-fill: var(--info-300);
    --stroke-s1-on-weak-fill: var(--supplementary-1-300);
    --stroke-s2-on-weak-fill: var(--supplementary-2-300);
    --stroke-s3-on-weak-fill: var(--supplementary-3-300);
    --stroke-s4-on-weak-fill: var(--supplementary-4-300);
    --stroke-s5-on-weak-fill: var(--supplementary-5-300);
    --content-tertiary: var(--neutral-500);
    --content-inverse-tertiary: var(--neutral-400);
    --content-error-on-weak-fill: var(--error-900);
    --content-success-on-weak-fill: var(--success-900);
    --content-warning-on-weak-fill: var(--warning-900);
    --content-info-on-weak-fill: var(--info-900);
    --fill-elevated-primary: var(--neutral-100);
    --background-inverse: var(--neutral-900);
    --stroke-error: var(--error-600);
    --stroke-success: var(--success-600);
    --stroke-accent-on-weak-fill: var(--accent-300);
    --content-accent-on-weak-fill: var(--accent-900);
    --fill-elevated-secondary: var(--neutral-250);
    --fill-base-secondary: var(--neutral-150);
    --fill-base: var(--neutral-200);
    --content-quaternary: var(--neutral-400);
    --fill-accent-secondary: var(--accent-400);
    --fill-accent-tertiary: var(--accent-300);
    --content-accent-secondary: var(--accent-600);
    --content-accent-tertiary: var(--accent-400);
    --fill-elevated-tertiary: var(--neutral-350);
    --content-s1: var(--supplementary-1-500);
    --content-s2: var(--supplementary-2-500);
    --content-s3: var(--supplementary-3-500);
    --content-s4: var(--supplementary-4-500);
    --content-s5: var(--supplementary-5-500);
    --content-s1-weak: var(--supplementary-1-300);
    --content-s1-strong: var(--supplementary-1-700);
    --content-s2-weak: var(--supplementary-2-300);
    --content-s2-strong: var(--supplementary-2-700);
    --content-s3-weak: var(--supplementary-3-300);
    --content-s3-strong: var(--supplementary-3-700);
    --content-s4-weak: var(--supplementary-4-300);
    --content-s4-strong: var(--supplementary-4-700);
    --content-s5-weak: var(--supplementary-5-300);
    --content-s5-strong: var(--supplementary-5-800);

    // button colors

    --button-primary-fill: var(--fill-accent);
    --button-primary-content: var(--content-inverse);
    --button-primary-fill-hover: var(--accent-700);
    --button-secondary-fill: var(--fill-tertiary);
    --button-secondary-fill-hover: var(--neutral-400);
    --button-secondary-content: var(--content-primary);
    --button-tertiary-content: var(--content-primary);
    --button-destructive-fill: var(--fill-error);
    --button-destructive-fill-hover: var(--error-700);
    --button-destructive-content: var(--content-inverse);
    --button-success-fill: var(--fill-success);
    --button-success-fill-hover: var(--success-700);
    --button-tertiary-fill: transparent;
    --button-success-content: var(--content-inverse);
    --button-text-primary-content: var(--content-accent);
    --button-text-secondary-content: var(--content-primary);
    --button-text-destructive-content: var(--content-error);
    --button-tertiary-stroke: var(--stroke-inverse-default);
    --button-tertiary-fill-hover: var(--neutral-350);
    --button-text-destructive-fill-hover: var(--error-250);
    --button-text-primary-fill-hover: var(--accent-250);
    --button-text-secondary-fill-hover: var(--neutral-350);
    --button-text-tertiary-content: var(--content-tertiary);
    --button-text-tertiary-content-hover: var(--content-primary);
    --navbar-active-fill: var(--neutral-925);
    --navbar-active-stroke: var(--neutral-310);
    --button-primary-on-accent-fill: var(--fill-elevated-primary);
    --button-primary-on-accent-fill-hover: var(--fill-elevated-tertiary);
    --button-primary-on-accent-content: var(--content-accent-secondary);
    --button-secondary-on-accent-stroke: var(--content-inverse);
    --button-secondary-on-accent-fill-hover: var(--fill-elevated-tertiary);
    --button-secondary-on-accent-content: var(--content-inverse);
    --button-tertiary-accent-stroke: var(--content-accent-secondary);
    --button-tertiary-accent-content: var(--content-accent-secondary);
    --button-tertiary-accent-fill-hover: var(--accent-250);
    --button-plain-primary-outlined-color: var(--content-secondary);
    --button-plain-primary-outlined-fill: var(--content-inverse);
    --button-plain-primary-outlined-stroke: var(--stroke-default);
}

@use 'sass:math';
@import '../core/Utils';

.AppCore {
    @mixin make-container-gutters($gutter) {
        &.gutter-#{strip-unit($gutter)} {
            > .Row {
                margin: math.div(-$gutter, 2);

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                > .Col {
                    padding: calc($gutter / 2);
                }

                & + .Row {
                    margin-top: calc($gutter / 2);
                }
            }
        }
    }

    .container {
        width: auto;
        margin: 0 auto;
        max-width: map-get($container-max-widths, 'desktop');

        &.full-width {
            max-width: none;
            width: 100%;
        }

        &.limited-width {
            max-width: 1138px;
        }

        &.stickToLeft {
            margin-left: 0;
        }

        > .Row {
            display: flex;

            &.wrap {
                flex-wrap: wrap;
            }

            &.v-align-start {
                align-items: flex-start !important;
            }

            &.v-align-center {
                align-items: center !important;
            }

            &.v-align-end {
                align-items: flex-end !important;
            }

            &.v-align-stretch {
                align-items: stretch !important;
            }

            &.v-align-baseline {
                align-items: baseline !important;
            }

            &.justify-start {
                justify-content: flex-start !important;
            }

            &.justify-end {
                justify-content: flex-end !important;
            }

            &.justify-center {
                justify-content: center !important;
            }

            &.justify-between {
                justify-content: space-between !important;
            }

            &.justify-around {
                justify-content: space-around !important;
            }

            &.reverse-sm {
                @include media-breakpoint-down(none) {
                    flex-direction: column-reverse;
                }
            }

            > .Col {
                min-width: 0;
                flex-shrink: 0;

                &.full-width {
                    flex: 1;
                }

                &.can-shrink {
                    flex-shrink: 1;
                }

                &.h-align-start {
                    margin-left: 0;
                    margin-right: auto;

                    &:first-child {
                        margin-right: 0;
                    }
                }

                &.h-align-start + .Col.h-align-start {
                    margin-right: 0;
                }

                &.h-align-center {
                    margin-left: auto;
                    margin-right: auto;
                }

                &.h-align-end {
                    margin-left: auto;
                    margin-right: 0;
                }

                &.h-align-center + .Col.h-align-end,
                &.h-align-end + .Col.h-align-end {
                    margin-left: 0;
                }

                &.v-align-start {
                    align-self: flex-start !important;
                }

                &.v-align-center {
                    align-self: center !important;
                }

                &.v-align-end {
                    align-self: flex-end !important;
                }
            }
        }

        @include make-container-gutters(14px);
        @include make-container-gutters(16px);
        @include make-container-gutters(19px);
        @include make-container-gutters(24px);
        @include make-container-gutters(30px);
    }

    > .container {
        width: calc(100% - 20px);
        margin: 0 auto;

        @include media-breakpoint-up(tablet) {
            width: calc(100% - 40px);
        }

        @include media-breakpoint-up(desktop) {
            max-width: map-get($container-max-widths, 'desktop');
        }
    }
}

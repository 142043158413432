@import 'core';

.AppCore {
    .SteamerTriggerContainer {
        position: relative;

        .SteamerCounter {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $color-error;
            vertical-align: middle;
            color: $white;
        }
    }
}

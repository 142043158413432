@import 'core';

.typography {
    font-family: $font-family-base;
    color: var(--content-primary);

    &.display-1,
    &.display-2,
    &.display-3 {
        font-weight: $font-weight-semibold;
    }

    &.display-1 {
        font-size: $font-size-display-1;
        line-height: $line-height-display-1;
    }

    &.display-2 {
        font-size: $font-size-display-2;
        line-height: $line-height-display-2;
    }

    &.display-3 {
        font-size: $font-size-display-3;
        line-height: $line-height-display-3;
    }
    &.display-4 {
        font-size: $font-size-display-4;
        line-height: $line-height-display-4;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.heading-1,
    &.heading-2,
    &.heading-3,
    &.heading-4,
    &.heading-5,
    &.heading-6 {
        font-weight: $font-weight-semibold;
        margin-bottom: 12px;
    }

    h1,
    &.heading-1 {
        font-size: $font-size-heading-1; // 36
        line-height: $line-height-heading-1;
        margin-bottom: 10px;
    }

    h2,
    &.heading-2 {
        font-size: $font-size-heading-2; //32
        line-height: $line-height-heading-2;
    }

    h3,
    &.heading-3 {
        font-size: $font-size-heading-3; // 28
        line-height: $line-height-heading-3;
    }

    h4,
    &.heading-4 {
        font-size: $font-size-heading-4; // 24
        line-height: $line-height-heading-4;
    }

    h5,
    &.heading-5 {
        font-size: $font-size-heading-5; // 20
        line-height: $line-height-heading-5;
    }

    h6,
    &.heading-6 {
        font-size: $font-size-heading-6; // 16
        line-height: $line-height-heading-6;
    }

    &.body-lg {
        font-size: $font-size-body-lg; // 18
        line-height: $line-height-body-lg;
    }

    &.body,
    &.body-md {
        font-size: $font-size-body-md; // 16
        line-height: $line-height-body-md;
        font-weight: $font-weight-regular; // 400
    }

    &.body-sm {
        font-size: $font-size-body-sm; // 14
        line-height: $line-height-body-sm;
    }

    &.body-xs {
        font-size: $font-size-body-xs; // 12
        line-height: $line-height-body-xs;
    }

    &.weight-light {
        font-weight: $font-weight-light; // 300
    }

    &.weight-regular {
        font-weight: $font-weight-regular; // 400
    }

    &.weight-medium {
        font-weight: $font-weight-medium; // 500
    }

    &.weight-strong {
        font-weight: $font-weight-semibold; // 600
    }

    &.align-left {
        text-align: left;
    }

    &.align-right {
        text-align: right;
    }

    &.align-center {
        text-align: center;
    }

    &.align-justify {
        text-align: justify;
    }

    &.align-inherit {
        text-align: inherit;
    }

    &.trim {
        line-height: 1em;
    }

    b,
    strong {
        font-weight: $font-weight-medium;
    }

    p {
        margin-bottom: 12px;
    }
}

@import 'core';

.AppCore {
    .DateTimePickerWrap {
        outline: none;

        .InputBaseWrapperInner {
            box-shadow: none;
        }

        .InputIconRight .Icon {
            color: $primary-blue;
        }

        &.InputBaseWithErrorWrapper {
            .InputIconRight .Icon {
                color: $color-error;
            }
        }

        &.InputBaseDisabledWrapper {
            .InputIconRight .Icon {
                opacity: 0.34;
            }
        }
    }

    .DateTimePickerDropdownBody {
        display: flex;
        justify-content: flex-end;
        padding-top: 7px;
        padding-bottom: 7px;

        > .Card {
            padding: 0;
        }
    }

    .DateTimePickerInputIconRemove {
        position: absolute;
        display: block;
        top: 0;
        height: 100%;
        right: 35px;
        padding: 0 5px;

        .Icon:not(#hack) {
            width: 16px;
            height: 16px;
        }

        &:before {
            content: '';
            height: 100%;
        }

        .Icon:not(#hack),
        &:before {
            display: inline-block;
            vertical-align: middle;
        }
    }
}

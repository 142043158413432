@import 'core';

// TODO: use new colors after we have design for it
.AppCore {
    .ToggleButton {
        display: block;
        position: relative;
        .InputBaseWrapperInner {
            box-shadow: none;
        }

        &.InputBaseDisabledWrapper {
            pointer-events: none;
        }

        input[type='checkbox'] {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
        }

        &.ToggleButtonOn .Button.primary {
            background-color: var(--button-primary-fill-hover);
        }

        &.ToggleButtonOn .Button.secondary {
            background-color: var(--button-secondary-fill-hover);
            border-color: var(--button-secondary-fill);
        }

        &.ToggleButtonOn .Button.tertiary {
            background-color: var(--button-tertiary-fill-hover);
            border-color: var(--button-tertiary-stroke);
        }

        &.ToggleButtonOn .Button.plainPrimaryOutlined {
            color: var(--button-text-primary-content);
            border-color: var(--button-primary-fill);
            background-color: var(--button-text-primary-fill-hover);
        }
    }
}

@import 'core';

$app-notifications-top: 40px;
$app-notifications-bottom: 10px;
$app-notifications-height: 62px;

.AppCore {
    .Notification.AppNotification {
        position: fixed;
        z-index: $depth-above-everything;

        @include media-breakpoint-down(none) {
            left: 10px;
            right: 10px;
            transition:
                bottom 0.1s ease-in-out,
                top 0.1s ease-in-out;

            &.visible {
                bottom: $app-notifications-bottom;
            }

            &.index-0 {
                color: inherit;
            }

            &.index-1 {
                bottom: $app-notifications-bottom + $app-notifications-height;
            }

            &.index-2 {
                bottom: $app-notifications-bottom + $app-notifications-height * 2;
            }

            &.index-3 {
                bottom: $app-notifications-bottom + $app-notifications-height * 3;
            }

            &.index-4 {
                bottom: $app-notifications-bottom + $app-notifications-height * 4;
            }

            &.adding {
                bottom: -100%;
            }

            &.removing {
                bottom: -100%;
            }
        }

        @include media-breakpoint-up(mobile) {
            top: $app-notifications-top;
            transition:
                right 0.1s ease-in-out,
                top 0.1s ease-in-out;

            &.index-0 {
                color: inherit;
            }

            &.index-1 {
                top: $app-notifications-top + $app-notifications-height;
            }

            &.index-2 {
                top: $app-notifications-top + $app-notifications-height * 2;
            }

            &.index-3 {
                top: $app-notifications-top + $app-notifications-height * 3;
            }

            &.index-4 {
                top: $app-notifications-top + $app-notifications-height * 4;
            }

            &.adding {
                right: -100%;
            }

            &.visible {
                right: 30px;
            }

            &.removing {
                right: -100%;
            }
        }
    }
}

@import 'core';

.AppCore {
    .ButtonGroup {
        display: flex;

        &:not(.Separated) {
            > * {
                &:first-child(:last-child) {
                    &.Button,
                    &.Dropdown > .DropdownHeader > .Button {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }

                &:not(:first-child) {
                    &.Button,
                    & .Button,
                    &.Dropdown > .DropdownHeader > .Button {
                        border-bottom-left-radius: 0;
                        border-top-left-radius: 0;
                    }
                }

                &:not(:last-child) {
                    &.Button,
                    & .Button,
                    &.Dropdown > .DropdownHeader > .Button {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }

                &:last-child(:first-child) {
                    &.Button,
                    &.Dropdown > .DropdownHeader > .Button {
                        border-bottom-left-radius: 0;
                        border-top-left-radius: 0;
                    }
                }
            }
        }

        > .Button,
        > .Button + .Dropdown,
        > .ToggleButton,
        > .ToggleButton + .Dropdown {
            position: relative;
            z-index: 1;

            &:hover,
            &.active,
            &.ToggleButtonOn {
                z-index: 2;
            }
        }

        &:not(.Separated) {
            > .Button + .Button,
            > .Button + .Dropdown,
            > .ToggleButton + .ToggleButton,
            > .Button + .ToggleButton,
            > .ToggleButton + .Button,
            > .ToggleButton + .Dropdown {
                margin-left: -1px;
            }
        }
    }
}

@import 'core';

.AppCore {
    .CheckboxList {
        outline: none;

        .InputBaseWrapperInner {
            box-shadow: none;
            outline: none;
        }

        &.InputBaseDisabledWrapper {
            .Checkbox,
            .Checkbox .CheckboxLabel {
                pointer-events: none;
                cursor: default;
            }
        }
    }

    .HiddenSelect {
        height: 0;
        width: 0;
        line-height: 0;
        overflow: hidden;
        outline: none;
        border: none;
        opacity: 0;
        position: absolute;
    }
}

@import 'core';

.AppCore {
    .InputBaseWrapper {
        display: block;
        width: 100%;
        margin-bottom: 0;
    }

    .InputBaseWrapperInner {
        display: block;
        width: 100%;
    }

    .InputBaseLabel {
        display: block;
        margin-bottom: 4px;
        margin-left: 4px;

        color: var(--content-primary);
    }

    .InputBaseDescription,
    .InputBaseError {
        display: block;
        margin-top: 4px;
        margin-left: 4px;

        color: var(--content-tertiary);
        font-weight: $font-weight-regular;

        .Icon {
            color: var(--content-tertiary);
        }
    }

    .InputBaseError {
        color: var(--content-error);

        .Icon {
            color: var(--content-error);
        }
    }

    .InputBaseDisabledWrapper {
        cursor: default;

        .InputBaseWrapperInner {
            box-shadow: none;
        }
    }

    .InputBaseWithErrorWrapper {
        color: inherit;
    }

    &.DarkMode {
        .InputBaseWrapperInner {
            box-shadow: none;
        }
    }
}

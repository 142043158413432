@import 'core';

.AppCore {
    .HTMLEditor {
        padding: 0;
        font-family: inherit;
        border: 1px solid $grey-light-1;
        border-radius: 4px;
        background: $white;
        color: $grey-dark-1;
        font-size: 18px;

        [class^='EditorToolbar__'] {
            border-bottom: 1px solid $grey-light-1;
            margin: 0;
            padding: 16px 19px 16px 5px;
            font-size: 18px;
            line-height: 20px;
            font-family: 'Calibre', sans-serif;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            > em,
            > span {
                display: block;
                margin-left: 9px;
                margin-right: 9px;
                width: 1px;
                height: 16px;
                background: transparent;
            }

            > em {
                background: $grey-light-1;
            }

            > .Dropdown {
                width: 208px;
            }

            .ToggleButton .Button.tertiary.xs {
                padding-left: 2px;
                padding-right: 2px;
            }

            > .container {
                margin: 0;

                > .Row > .Col:not(:last-child) {
                    > .ToggleButton .Button.tertiary.xs {
                        margin-right: 9px;
                    }
                }
            }
        }

        [class^='RichTextEditor__editor___'] {
            background: $grey-light-4;
        }
    }
    .HTMLTableEditor {
        padding: 16px;

        .EditorToolbar__x {
            padding-top: 10px;
            padding-bottom: 11px;
        }

        .CmsItemBlocksInputBlockTableEditorTable {
            width: 100%;

            .RTEditor {
                [class^='EditorToolbar__'] {
                    display: none;
                }
            }

            td {
                vertical-align: top;
            }
        }
    }
}

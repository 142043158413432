@import 'core';

.AppCore {
    .RTEditor {
        display: block;
        height: 100%;

        .InputBaseWrapperInner {
            display: flex;
            height: 100%;
        }

        .ContentEditable {
            display: inline-block;
            width: 100%;
            height: auto;
            outline: none;
            border: none;
            text-decoration: inherit;
            word-wrap: normal;
        }

        .public-DraftEditor-content {
            line-height: initial;
        }

        .public-DraftStyleDefault-block,
        .public-DraftStyleDefault-ul,
        .public-DraftStyleDefault-ol {
            margin-top: 0;
            margin-bottom: 12px;
        }

        .public-DraftStyleDefault-ul,
        .public-DraftStyleDefault-ol {
            .public-DraftStyleDefault-block {
                margin-bottom: 0;
            }
        }
    }

    .RTEditorWrap {
        position: relative;
        width: 100%;
    }

    .RTEditorPlaceholder {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        color: $grey-dark-2;
        pointer-events: none;
        user-select: none;
        text-decoration: inherit;
        overflow: hidden;
    }
}

@use 'sass:math';
@import 'core';

.AppCore {
    .CardToggleHeader {
        border-bottom: none;
    }

    .CardToggleHeaderText {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        color: var(--content-primary);
    }

    .CardToggleHeaderArrow {
        color: var(--content-tertiary);

        &:hover {
            color: var(--content-secondary);
        }
    }

    .CardToggleBody {
        margin-top: 16px;
    }
}

@import 'core';

.AppCore {
    .DayView {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        border: 1px solid $grey-light-1;
        overflow-x: auto;

        .DayViewHeader {
            height: 48px;
            min-width: 800px;
            display: flex;
            width: 100%;
            position: sticky;
            top: 0;
            z-index: 10;
            box-shadow: $eleveted-surface-shadow;
            padding: 16px;
            align-items: center;
        }
    }

    .DayColumns {
        min-width: 800px;
        display: grid;
        grid-template-columns: 60px repeat(1, 1fr);
        flex-grow: 1;
        position: relative;
        overflow-y: auto;
        height: 100%;
        width: 100%;
        background-color: $body-elevated-background;
    }
}

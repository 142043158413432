@mixin flex-utility($direction: null, $align: null, $justify: null, $wrap: null) {
    @if $direction {
        flex-direction: $direction;
    }
    @if $align {
        align-items: $align;
    }
    @if $justify {
        justify-content: $justify;
    }
    @if $wrap {
        flex-wrap: $wrap;
    }
}

.flex {
    display: flex;

    @include flex-utility();

    &-row {
        @include flex-utility(row);
    }

    &-column {
        @include flex-utility(column);
    }

    &-align {
        &-center {
            @include flex-utility(null, center);
        }

        &-start {
            @include flex-utility(null, flex-start);
        }

        &-end {
            @include flex-utility(null, flex-end);
        }
    }

    &-justify {
        &-center {
            @include flex-utility(null, null, center);
        }

        &-start {
            @include flex-utility(null, null, flex-start);
        }

        &-end {
            @include flex-utility(null, null, flex-end);
        }
    }

    &-wrap {
        @include flex-utility(null, null, null, wrap);
    }
}

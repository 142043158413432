@import 'core';

.AppCore {
    &:not(#app-portal-container) {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .MainNavigation {
        flex: 1;
        width: 100%;
        height: auto;
        padding: 100px 30px 12px (30px + $nav-width);
        margin-left: 0;
        transition: margin-left 0.2s;

        &.FullScreenHeight {
            height: 100vh;
            display: flex;
            flex-direction: column;
        }

        @media print {
            padding: 0;
        }
    }

    &.DarkMode {
        .MainNavigation {
            background-color: $grey-dark-5;
            color: $white;
        }
    }

    .SidebarNavigation {
        z-index: $depth-main-sidebar;
    }

    @include media-breakpoint-up(mobile) {
        .SidebarNavigation.DetailedViewOpened {
            & + .MainNavigation {
                margin-left: $nav-detailed-view-width;
                width: calc(100% - #{$nav-detailed-view-width});
            }
        }
    }

    .MainNavigation > .container {
        position: relative;
        z-index: 1;

        &.HeaderMenu {
            z-index: 2;
            position: absolute;
            top: 0;
            right: 0;
            bottom: auto;
            left: 0;
        }
    }

    @include media-breakpoint-down(none) {
        .MainNavigation {
            padding-left: 30px;
        }

        .SidebarNavigation + .MainNavigation {
            padding-top: $nav-sm-height + 12px;
        }

        .SidebarNavigation.MobileViewOpened {
            & + .MainNavigation {
                overflow: hidden;
            }
        }
    }
}

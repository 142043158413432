@import 'core';

.AppCore {
    @mixin make-color($name, $color) {
        .Text-#{$name} {
            color: $color !important;
        }

        .BG-#{$name} {
            background-color: $color !important;
        }

        .Border-#{$name} {
            border-color: $color !important;
        }
    }

    @include make-color('Black', $black);
    @include make-color('White', $white);
    @include make-color('Error', $color-error);
    @include make-color('ErrorLight', $color-error-light);
    @include make-color('ErrorLighter', $color-error-lighter);
    @include make-color('Success', $color-success);
    @include make-color('Warn', $color-warn);

    @include make-color('Primary-Green', $primary-green);
    @include make-color('Primary-GreenLight', $primary-green-light);
    @include make-color('Primary-Yellow', $primary-yellow);
    @include make-color('Primary-YellowLight', $primary-yellow-light);
    @include make-color('Primary-Blue', $primary-blue);
    @include make-color('Primary-BlueLight', $primary-blue-light);
    @include make-color('Primary-Violet', $primary-violet);
    @include make-color('Primary-VioletLight', $primary-violet-light);

    @include make-color('Grey-Dark1', $grey-dark-1);
    @include make-color('Grey-Dark2', $grey-dark-2);
    @include make-color('Grey-Dark3', $grey-dark-3);
    @include make-color('Grey-Dark4', $grey-dark-4);
    @include make-color('Grey-Dark5', $grey-dark-5);
    @include make-color('Grey-Light1', $grey-light-1);
    @include make-color('Grey-Light2', $grey-light-2);
    @include make-color('Grey-Light3', $grey-light-3);
    @include make-color('Grey-Light4', $grey-light-4);
}

@import 'core';

.AppCore .PasswordInput {
    .TogglePasswordVisibility {
        border-bottom: none;

        .Icon {
            color: $grey-dark-2;
        }
    }
}

@import 'core';

.AppCore {
    .ManageColumnRow {
        font-size: 22px;
        padding: 10px;
        border-radius: 3px;
        background-color: $grey-light-4;
        &:hover {
            background-color: $grey-light-2;
        }
    }
}

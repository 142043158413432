@import 'core';

.AppCore .SuggestionsInput {
    position: relative;

    .SuggestionsInputDropdown {
        position: absolute;
        top: 90px;
        left: 0;
        right: 0;
        z-index: 2000;
    }

    .SuggestionsInputSuggestion {
        padding: 6px 12px;
        cursor: pointer;

        &:hover {
            background-color: $grey-light-3;
        }
    }
}

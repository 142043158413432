@import 'core';

.AppCore {
    .WeeklyView {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        border: 1px solid $grey-light-1;
        overflow-x: auto;

        .dayColumns {
            min-width: 800px;
            display: grid;
            grid-template-columns: 60px repeat(7, 1fr);
            flex-grow: 1;
            position: relative;
            overflow-y: auto;
            height: 100%;
            width: 100%;
            background-color: $body-elevated-background;
        }
        .CalendarHeader {
            min-width: 800px;
            display: grid;
            grid-template-columns: 60px repeat(7, 1fr);
            position: sticky;
            top: 0;
            z-index: 10;
            box-shadow: $eleveted-surface-shadow;
        }

        .dayHeader {
            height: 48px;
            padding: 16px;
            border-bottom: 1px solid $grey-light-1;
            display: flex;
            align-items: center;
            border-left: 1px solid $grey-light-1;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            &:first-child {
                border-left: none;
            }
        }
    }
}

@import 'core';

.AppCore {
    .Tooltip {
        position: relative;
        display: inline-block;
    }

    a > .Tooltip {
        display: block;
        height: 100%;
    }

    .TooltipAnchor {
        pointer-events: none;
        z-index: 10999 !important;

        @include media-breakpoint-down(none) {
            left: 0;
            right: 0;
            overflow: hidden;
        }
    }

    .TooltipText {
        display: block;
        opacity: 0;
        pointer-events: none;
        position: relative;
        color: var(--content-primary);
        padding: 6px 13px;
        background-color: var(--fill-tertiary);
        border-radius: 4px;

        box-shadow: 1px 1px 5px 0 rgba(219, 227, 235, 0.2);

        > span {
            display: block;
            width: 100%;
            @include media-breakpoint-up(mobile) {
                white-space: nowrap;
            }
        }

        a {
            color: $grey-dark-1;

            &:hover,
            &:active {
                color: $primary-blue;
            }
        }

        &.max-width {
            > span {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &.fixed-width {
            > span {
                overflow: auto;
                text-overflow: unset;
                white-space: normal;
            }
        }

        &.TooltipVisible {
            opacity: 1;
            pointer-events: auto;
            z-index: 1;
        }

        &.TooltipDisabled {
            display: none !important;
        }

        &.TooltipDisabledIfNoOverflow {
            &.TooltipVisible {
                display: none;
            }
        }

        &.NonInteractable {
            pointer-events: none;
        }

        &.bottom {
            margin-top: 6px;

            &:before {
                display: block;
                content: '';
                position: absolute;
                top: -6px;
                left: 13px;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 6px solid var(--fill-tertiary);
            }

            &:after {
                display: block;
                content: '';
                position: absolute;
                top: -6px;
                left: 0;
                right: 0;
                height: 6px;
            }
        }

        &.top {
            margin-top: -6px;

            &:before {
                display: block;
                content: '';
                position: absolute;
                bottom: -6px;
                left: 13px;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid var(--fill-tertiary);
            }

            &:after {
                display: block;
                content: '';
                position: absolute;
                bottom: -6px;
                left: 0;
                right: 0;
                height: 6px;
            }
        }

        &.top-center {
            &:before {
                display: block;
                content: '';
                position: absolute;
                bottom: -6px;
                left: 50%;
                margin-left: -6px;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid var(--fill-tertiary);
            }

            &:after {
                display: block;
                content: '';
                position: absolute;
                bottom: -6px;
                left: 0;
                right: 0;
                height: 6px;
            }
        }

        &.bottom-center {
            margin-top: 6px;

            &:before {
                display: block;
                content: '';
                position: absolute;
                top: -6px;
                left: 50%;
                margin-left: -6px;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 6px solid var(--fill-tertiary);
            }

            &:after {
                display: block;
                content: '';
                position: absolute;
                top: -6px;
                left: 0;
                right: 0;
                height: 6px;
            }
        }

        &.right {
            margin-left: 6px;

            &:before {
                display: block;
                content: '';
                position: absolute;
                left: -6px;
                top: calc(50% - 6px);
                width: 0;
                height: 0;
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-right: 6px solid var(--fill-tertiary);
            }

            &:after {
                display: block;
                content: '';
                position: absolute;
                left: -6px;
                top: 0;
                bottom: 0;
                width: 6px;
            }
        }

        &.left {
            margin-left: -6px;

            &:before {
                display: block;
                content: '';
                position: absolute;
                right: -6px;
                top: calc(50% - 6px);
                width: 0;
                height: 0;
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-left: 6px solid var(--fill-tertiary);
            }

            &:after {
                display: block;
                content: '';
                position: absolute;
                right: -6px;
                top: 0;
                bottom: 0;
                width: 6px;
            }
        }
    }
}

@import 'core';

.AppCore {
    .DialogModal,
    .DialogModal:before,
    .DialogModalBody {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .DialogModal {
        position: fixed;
        z-index: $depth-above-everything;

        &:before {
            content: '';
            display: block;
            background: $grey-dark-1;
            opacity: 0.3;
            cursor: pointer;
        }
    }

    .DialogModalBody {
        color: inherit;
        top: 25%;
        right: 35%;
        left: 35%;
        bottom: auto;
        min-width: 450px;
    }
}

@import 'core';

.AppCore {
    .InfoCmsItemAction {
        border-bottom: none;

        > .Icon {
            color: var(--content-quaternary);
        }

        &:hover > .Icon {
            color: var(--content-tertiary);
        }
    }
}

@import 'core';

.AppCore {
    @include media-breakpoint-down(none) {
        .hidden-sm {
            display: none !important;
        }

        .full-screen-sm {
            position: fixed !important;
            z-index: 5000 !important;
            top: $nav-sm-height + 22px !important;
            right: 28px !important;
            bottom: 28px !important;
            left: 28px !important;
            min-width: 0 !important;
            display: flex;
            align-items: center;
        }

        .hidden-overflow-sm {
            > .container {
                overflow: hidden;
            }
        }
    }

    @include media-breakpoint-up(mobile) {
        .hidden-md {
            display: none !important;
        }
    }
}

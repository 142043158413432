@import 'core';

$detailed-view-transition: 0.2s;

.SidebarNavigation {
    position: fixed;
    top: 0;
    left: 0;

    @include media-breakpoint-up(mobile) {
        bottom: 0;
        width: $nav-width;
    }

    @include media-breakpoint-down(none) {
        right: 0;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        bottom: 0;

        background: $grey-dark-1;

        @include media-breakpoint-up(mobile) {
            width: $nav-width;
            border-top-right-radius: 20px;
        }

        @include media-breakpoint-down(none) {
            right: 0;
        }
    }

    .AppIcon {
        position: relative;
        display: block;
        width: $nav-width;
        z-index: 3;
        padding: 16px 0 0 15px;
        border-bottom: none;

        img {
            display: block;
            border-radius: 14px;
            width: 42px;
            height: 42px;
        }
    }

    .TopNavigation {
        position: relative;
        z-index: 3;

        @include media-breakpoint-up(mobile) {
            margin-top: 14px;
            width: $nav-width;
        }

        @include media-breakpoint-down(none) {
            margin-top: $nav-sm-height;
            width: 100%;
            height: 0;
            overflow: auto;
            transition: height $detailed-view-transition;

            .typography {
                color: var(--neutral-100);
            }

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 0;
                border-bottom: 1px solid $grey-dark-4;
                position: fixed;
                top: $nav-sm-height;
                left: 0;
            }
        }
    }

    @include media-breakpoint-up(mobile) {
        .TopNavigationItem {
            display: block;
            position: relative;
            cursor: pointer;

            .typography {
                color: $grey-dark-1;
            }

            text-decoration: none !important;

            &:last-child {
                border-bottom: 1px solid $grey-dark-4;
            }

            > a,
            > div {
                display: block;
                width: 100%;
                height: $nav-width;
                border-bottom: none;
                line-height: $nav-width;
                text-align: center;

                @include media-breakpoint-down(desktop) {
                    height: 60px;
                    line-height: 60px;
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: $grey-dark-1;
                    border-top: 1px solid $grey-dark-4;
                }
            }

            .Icon {
                position: relative;
                z-index: 2;
                display: inline-block;
                color: $white;
                &.l {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;

                    @include media-breakpoint-down(desktop) {
                        width: 32px;
                        height: 32px;
                        line-height: 30px;
                    }
                }
            }

            .TopNavigationItemLabel {
                display: flex;
                align-items: center;
                position: absolute;
                top: 0;
                left: $nav-width - $nav-detailed-view-width;
                transition: left $detailed-view-transition;

                width: $nav-detailed-view-width;
                height: 100%;
                padding: 12px 35px 8px 25px;

                > div {
                    width: 100%;
                    max-height: 58px;
                    line-height: 20px;
                    overflow-y: hidden;
                    text-align: left;
                }

                > .Icon {
                    display: none;
                    position: absolute;
                    top: 50%;
                    margin-top: -12px;
                    right: 7px;
                }
            }
        }

        .TopNavigationItem:hover > a,
        .TopNavigationItem:hover > div,
        .TopNavigationItem > a.TopNavigationItemActive {
            &:after {
                background-color: $white;
                border-top-color: $white;
                width: $nav-width;
            }

            .Icon {
                color: $primary-blue;
            }
        }
    }

    @include media-breakpoint-down(none) {
        .TopNavigationItem {
            display: block;
            position: relative;
            color: $grey-dark-1;

            text-decoration: none !important;
            border-bottom: 1px solid $grey-dark-4;

            > a,
            > div {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                border-bottom: none;
                line-height: 32px;

                padding: 12px 0;

                > .Icon {
                    margin-left: 16px;
                }
            }

            .Icon {
                position: relative;
                z-index: 2;
                color: $white;

                &.l {
                    width: 32px;
                    height: 32px;
                    line-height: 30px;
                }
            }

            .TopNavigationItemLabel {
                width: calc(100% - 48px);

                > div {
                    padding-left: 10px;
                }
            }

            .TopNavigationItemChildren {
                width: 100%;
            }
        }

        // .TopNavigationItem:hover > a,
        // .TopNavigationItem:hover > div,
        // .TopNavigationItem > a.TopNavigationItemActive {
        //     &:after {
        //         background-color: $white;
        //         border-top-color: $white;
        //         width: $nav-width;
        //     }

        //     .Icon {
        //         color: $primary-blue;
        //     }
        // }
    }

    @include media-breakpoint-down(none) {
        .TopNavigationItem {
            > a,
            > div {
                color: $white;
            }
        }
    }

    .DetailedView {
        position: absolute;
        z-index: 1;
        top: 0;
        left: -$nav-detailed-view-width + $nav-width;
        bottom: 0;
        width: $nav-detailed-view-width;
        background-color: $white;
        border-top-right-radius: 20px;
        box-shadow: 0 2px 8px 0 rgba(185, 199, 209, 0.5);
        transition: left $detailed-view-transition;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -16px;
            width: 20px;
            height: 20px;
            background-color: $white;
        }

        .AppTitle {
            display: block;
            padding: 13px 0 0 6px;
            border-bottom: none;

            > img {
                display: block;
                width: 150px;
                height: 54px;
                text-decoration: none;
            }
        }

        .TopNavigation {
            width: 100%;
            margin-top: 16px;
        }

        .TopNavigationItemIcon {
            display: none;

            position: absolute;
            top: 50%;
            right: 16px;
            margin-top: -9px;

            color: $primary-blue;
        }
    }

    @include media-breakpoint-up(mobile) {
        .DetailedViewToggleButton {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            background: $primary-blue;
            border-radius: 50%;
            border-bottom: none;
            position: absolute;
            z-index: 4;
            top: 62px;
            left: $nav-width - 14px;
            transition: left $detailed-view-transition;

            .Icon {
                width: 19px;
                height: 19px;
                color: $white;
            }

            @include media-breakpoint-between(mobile, tablet) {
                width: 35px;
                height: 35px;
                top: 65px;
                left: $nav-width - 18px;
            }
        }
    }

    @include media-breakpoint-down(none) {
        .DetailedViewToggleButton {
            position: absolute;
            z-index: 4;
            top: 8px;
            left: 8px;
            border-bottom: none;

            > span {
                display: flex;
                align-items: center;
                justify-content: center;

                > img {
                    width: 48px;
                    height: 48px;
                }

                > span {
                    scale: 1;
                    transition: scale $detailed-view-transition;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 24px;
                    margin-left: 12px;
                    background: $primary-blue;
                    border-radius: 50%;
                    border-bottom: none;

                    .Icon {
                        width: 19px;
                        height: 19px;
                        color: $white;
                    }
                }

                &.collapsed {
                    > span {
                        scale: 0;
                    }
                }
            }
        }
    }

    > .TopNavigation {
        @include media-breakpoint-up(mobile) {
            .TopNavigationItemActive {
                &:after {
                    content: '';
                    display: block;
                    background-color: $white;
                    width: $nav-width;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    transition:
                        right $detailed-view-transition,
                        width $detailed-view-transition;
                }
            }
        }
    }

    &.DetailedViewOpened {
        @include media-breakpoint-up(mobile) {
            width: $nav-width + $nav-detailed-view-width;

            .TopNavigationItem {
                display: flex;
                position: relative;

                width: 100%;
                margin-bottom: 0;

                .TopNavigationItemLabel {
                    left: $nav-width;
                }
            }

            .TopNavigationItem:hover {
                .TopNavigationItemLabel {
                    > .Icon {
                        display: block;
                    }
                }
            }

            .TopNavigationItemActive {
                .TopNavigationItemLabel {
                    color: $primary-blue;
                }
            }

            > .TopNavigation {
                .TopNavigationItem:first-child .TopNavigationItemActive {
                    &:before {
                        content: '';
                        display: block;
                        background-color: $white;
                        width: 156px;
                        height: 20px;
                        position: absolute;
                        top: -1px;
                        left: 100%;
                    }
                }
            }

            .DetailedViewToggleButton {
                left: $nav-width + $nav-detailed-view-width - 13px;

                .Icon {
                    margin-left: -1px;
                }
            }

            .DetailedView {
                left: $nav-width;
            }
        }
    }

    &.MobileViewOpened {
        @include media-breakpoint-down(none) {
            .TopNavigation {
                height: 100vh;
                padding-bottom: $nav-sm-height + 24px;
            }
        }
    }

    @include media-breakpoint-up(mobile) {
        .TopNavigationItemChildren {
            display: flex;
            position: absolute;
            left: $nav-width;
            margin-top: 16px;
            border-radius: 0 4px 4px 0;
            background: $white;
            box-shadow: $side-nav-shadow;
            transition: left $detailed-view-transition;

            transform: translateX(-9000px);
        }

        .TopNavigationItem.TopNavigationItemActive > div {
            .TopNavigationItemChildren {
                transform: translateX(0);
            }
        }

        &.DetailedViewOpened {
            .TopNavigationItem > div {
                .TopNavigationItemChildren {
                    left: $nav-width + $nav-detailed-view-width;
                }
            }
        }
    }
}

@include media-breakpoint-up(mobile) {
    .MegaMenu {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 320px;
        cursor: default;

        > div {
            width: 100%;
            padding: 14px;

            > a {
                display: block;
                border-bottom: none;
                position: relative;
                padding: 6px 6px 6px 52px;
                border-radius: 4px;
                z-index: 3;

                > div:last-child {
                    color: $grey-dark-2;
                    min-height: 40px;
                }

                > .Icon {
                    position: absolute !important;
                    top: 10px;
                    left: 10px;
                    color: $grey-dark-1 !important;
                    width: 32px !important;
                    height: 32px !important;
                }

                &:hover {
                    background: $grey-light-4;

                    > div:last-child {
                        color: $grey-dark-4 !important;
                    }

                    > .Icon {
                        color: $primary-blue !important;
                    }
                }
            }
        }

        &.MegaMenuWide {
            width: 640px;

            > div {
                width: 50%;
            }
        }
    }
}

@include media-breakpoint-down(none) {
    .MegaMenu.TopNavigationItemMegaMenu {
        > div {
            > a,
            > a.active {
                color: $white;
                border-bottom: none;
                display: flex;
                flex-wrap: wrap;
                padding: 8px 20px;

                > div {
                    width: calc(100% - 24px);
                    padding-left: 14px;
                    line-height: 24px;

                    &:last-child {
                        display: none;
                    }
                }

                &:hover,
                &:active,
                &.active {
                    background: $grey-dark-5;
                    color: $primary-blue;
                }
            }
        }
    }

    .TopNavigationItem {
        .TopNavigationItemChildren {
            max-height: 0;
            overflow: hidden;
            transition: max-height $detailed-view-transition;

            > .MegaMenu {
                padding: 4px 0 0 0;
            }
        }

        &.TopNavigationItemActive {
            .TopNavigationItemChildren {
                max-height: 200vh;
            }
        }
    }

    .MegaMenu:not(.TopNavigationItemMegaMenu) {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: auto;
        cursor: default;

        > div {
            width: 100%;
            padding: 14px;

            > a {
                display: block;
                border-bottom: none;
                position: relative;
                padding: 6px 6px 6px 52px;
                border-radius: 4px;
                text-align: left;
                line-height: 20px;
                z-index: 3;

                > div:last-child {
                    font-size: 14px;
                    color: $grey-dark-2;
                    min-height: 40px;
                }

                > .Icon {
                    position: absolute !important;
                    top: 10px;
                    left: 10px;
                    color: $grey-dark-1 !important;
                    width: 32px !important;
                    height: 32px !important;
                }

                &:hover {
                    background: $grey-light-4;

                    > div:last-child {
                        color: $grey-dark-4 !important;
                    }

                    > .Icon {
                        color: $primary-blue !important;
                    }
                }
            }
        }

        &.MegaMenuWide {
            width: auto;

            > div {
                width: 100%;
            }
        }
    }
}

@import 'core';

.AppCore .Preloader {
    display: block;
    position: relative;
    border-radius: 3px;

    @include media-breakpoint-down(none) {
        max-width: 100%;
    }

    &:after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        transform: rotate(180deg);
        background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.3),
            rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0.3),
            transparent
        );
        background-size: 200% 200%;

        -webkit-animation: PreloaderAnimation 3s ease infinite;
        -moz-animation: PreloaderAnimation 3s ease infinite;
        animation: PreloaderAnimation 3s ease infinite;
    }
}

@-webkit-keyframes PreloaderAnimation {
    0% {
        background-position: 10% 0;
    }
    50% {
        background-position: 91% 100%;
    }
    100% {
        background-position: 10% 0;
    }
}

@-moz-keyframes PreloaderAnimation {
    0% {
        background-position: 10% 0;
    }
    50% {
        background-position: 91% 100%;
    }
    100% {
        background-position: 10% 0;
    }
}

@keyframes PreloaderAnimation {
    0% {
        background-position: 10% 0;
    }
    50% {
        background-position: 91% 100%;
    }
    100% {
        background-position: 10% 0;
    }
}

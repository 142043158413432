@import 'core';

$colorScheme: (
    primary: (
        checked: var(--content-accent-secondary),
        intial: var(--content-tertiary),
    ),
    secondary: (
        checked: var(--content-success),
        intial: var(--content-secondary),
    ),
    error: (
        checked: var(--content-error),
        intial: var(--content-quaternary),
    ),
);
.AppCore {
    .Checkbox {
        position: relative;
        margin-bottom: 0;
        cursor: pointer;
        outline: none;

        input {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
        }

        .InputBaseWrapperInner {
            box-shadow: none;
            position: relative;
            min-width: 16px;
            display: flex;
            align-items: center;
        }

        &.c-s .InputBaseWrapperInner {
            line-height: 18px;
            height: 18px;
            min-width: 11px;
        }

        &.c-l .InputBaseWrapperInner {
            line-height: 24px;
            height: 24px;
        }

        .gfx {
            display: block;
            position: absolute;

            left: 0;

            color: var(--content-tertiary);

            > .Icon {
                color: inherit;
                vertical-align: top;
                width: 100%;
                height: 100%;
            }

            svg {
                fill: currentColor;
                vertical-align: top;
                width: 100%;
                height: 100%;
            }
        }

        &.c-l .gfx {
            width: 24px;
            height: 24px;
        }

        &.c-s .gfx {
            width: 18px;
            height: 18px;
        }

        &:focus input:checked {
            & + .gfx {
                opacity: 0.7;
            }
        }

        input:checked:disabled,
        input:checked:disabled:hover,
        &:focus input:checked:disabled,
        &.active input:checked:disabled,
        &.active input:checked:disabled:hover,
        &.active:focus input:checked:disabled {
            pointer-events: none;

            & + .gfx,
            & + .gfx + .CheckboxLabel {
                opacity: 0.6;
            }
        }

        input:disabled {
            & + .gfx,
            & + .gfx + .CheckboxLabel {
                opacity: 0.6;
            }
        }

        .CheckboxLabel {
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            color: var(--content-primary);
        }

        &.c-l .InputBaseWrapperInner .CheckboxLabel {
            padding-left: 12px;
            margin-left: 21px;
        }

        &.c-s .InputBaseWrapperInner .CheckboxLabel {
            padding-left: 13px;
            margin-left: 14px;
        }

        &.InputBaseDisabledWrapper {
            cursor: default;
        }

        @each $variant, $color in $colorScheme {
            &.#{$variant} {
                & + .gfx {
                    color: map-get($color, 'initial');
                }

                input:checked {
                    & + .gfx {
                        color: map-get($color, 'checked');
                    }
                }
            }
        }
    }

    &.DarkMode {
        .Checkbox {
            .CheckboxLabel {
                color: $white;
            }

            .gfx {
                color: $primary-blue;

                &:hover {
                    color: $primary-blue;
                }
            }

            input:checked + .gfx {
                color: $primary-blue;
            }

            input:hover + .gfx,
            &:focus input + .gfx {
                color: $primary-blue;
            }
        }
    }
}

.HeroBanner {
    width: 100%;
    position: relative;

    > img {
        display: block;
        width: 100%;
    }

    .LottieLayer {
        position: absolute;
    }
}

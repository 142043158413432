@import 'core';

.AppCore {
    .Toggle {
        cursor: pointer;
        outline: none;

        &.InputBaseWrapper {
            display: block;
            width: fit-content;
        }
        &:focus {
            opacity: 0.7;
        }
        .InputBaseWrapperInner {
            box-shadow: none;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        &.InputBaseDisabledWrapper {
            pointer-events: none;

            .InputBaseWrapperInner {
                opacity: 0.25;
            }
        }

        input[type='checkbox'] {
            opacity: 0;
            position: absolute;
            left: 0;
            cursor: pointer;
        }
    }

    .ToggleOn {
        font-style: inherit;
    }

    .ToggleLabel {
        display: inline-block;
        color: var(--content-primary);
    }

    .ToggleIcon {
        display: inline-block;
        margin-left: 12px;
        margin-top: 0;
        margin-bottom: 0;
        overflow: hidden;
        vertical-align: top;

        &.sm {
            margin-left: 10px;
        }

        .Icon {
            &.sm {
                height: 20px;
                width: 28px;
                line-height: 20px;

                svg {
                    margin-top: 3px;
                }
            }

            &.md {
                height: 20px;
                width: 40px;
                line-height: 20px;
            }

            @each $color in (secondary, tertiary, accent-secondary, success, error) {
                &.#{$color} {
                    color: var(--content-#{$color});
                }
            }
        }

        &.ToggleIconLabelRight {
            margin-left: 0;
            margin-right: 12px;

            &.sm {
                margin-right: 10px;
            }
        }
    }
}

@import 'core';

.AppCore {
    $table-border-radius: 4px;
    $table-border-color: $grey-light-1;

    .Table {
        display: table;
        position: relative;
        width: 100%;
        border-radius: $table-border-radius;
        background-color: var(--background-elevated-light);

        > .FixedHeadWrap > .Row,
        > .Row {
            display: table-row;

            > .Col {
                display: table-cell;
                vertical-align: top;
                color: $grey-dark-1;
                font-size: 18px;
                line-height: 22px;
                padding: 12px 16px;

                &.h-align-start {
                    text-align: left !important;
                }

                &.h-align-center {
                    text-align: center !important;
                }

                &.h-align-end {
                    text-align: right !important;
                }

                &.v-align-start {
                    vertical-align: top !important;
                }

                &.v-align-center {
                    vertical-align: middle !important;
                }

                &.v-align-end {
                    vertical-align: bottom !important;
                }
            }

            &.v-align-start > .Col {
                vertical-align: top !important;
            }

            &.v-align-center > .Col {
                vertical-align: middle !important;
            }

            &.v-align-end > .Col {
                vertical-align: bottom !important;
            }

            &:not(.TableHeader) {
                > .Col {
                    background-color: var(--background-elevated-light);
                }

                &:nth-child(2n + 1) {
                    > .Col {
                        background-color: var(--background-primary-light);
                    }
                }
            }

            &:last-child {
                > .Col {
                    border-bottom: none;

                    &:first-child {
                        border-bottom-left-radius: $table-border-radius;
                    }

                    &:last-child {
                        border-bottom-right-radius: $table-border-radius;
                    }
                }
            }
        }

        .Row.TableExtraHeaderRow,
        .Row.TableExtraHeaderRow > .Col {
            display: block;
            padding: 0;
        }

        &:not(.TableNoRowHover) {
            > .Row {
                &:not(.TableHeader) {
                    &.active {
                        > .Col {
                            background-color: var(--background-hover);
                        }
                    }
                }
            }
        }

        &:not(.TableNoRowHover) {
            > .Row {
                &:not(.TableHeader) {
                    &:hover,
                    &:hover.active {
                        > .Col {
                            background-color: var(--background-hover);
                        }
                    }
                }
            }
        }

        > .Row:not(.TableHeader) {
            > .Col:not(:first-child) {
                border-left: 1px solid $table-border-color;
            }
        }

        > .FixedHeadWrap > .TableHeader.Row,
        > .TableHeader.Row {
            > .Col {
                padding: 0;
                white-space: nowrap;
                border-bottom: 1px solid $table-border-color;

                > .HeadItem {
                    display: flex;
                    padding: 12px 16px;
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 20px;
                    background-color: var(--background-elevated-light);

                    > span > .Button {
                        vertical-align: middle;
                    }
                }

                &:first-child {
                    > .HeadItem {
                        border-top-left-radius: $table-border-radius;
                    }
                }

                &:last-child {
                    > .HeadItem {
                        border-top-right-radius: $table-border-radius;
                    }
                }
            }
        }

        .FixedHead {
            color: inherit;
        }

        .FixedHeadWrap {
            display: none;
            position: fixed;
            top: 0;
            z-index: 99;
            overflow: hidden;
            box-shadow: $table-header-shadow;

            .TableHeader.Row {
                > .Col {
                    border-radius: 0;

                    > .HeadItem {
                        border-top: 1px solid $table-border-color;
                        border-radius: 0;
                    }
                }
            }
        }

        .StaticHead {
            display: table-row;
            position: relative;
        }

        &.FixedHeaderVisible {
            .FixedHeadWrap {
                display: block;
            }
        }

        &.FixedInsideHeaderVisible {
            .FixedHeadWrap {
                display: block;
                position: absolute;
            }
        }

        .TableHeaderShadow {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 10px;
            margin-top: -10px;

            box-shadow: $table-header-shadow;
            clip: rect(0, 9998px, 16px, -25px);
        }

        .Table {
            .FixedHeadWrap {
                z-index: 98;
            }

            .Table {
                .FixedHeadWrap {
                    z-index: 97;
                }
            }
        }
    }

    .TableContainer {
        border-radius: $table-border-radius;
        border: 1px solid $table-border-color;
        max-width: none;

        &.NoOutline {
            border: none;

            .FixedHeadWrap {
                .TableHeader.Row {
                    > .Col {
                        > .HeadItem {
                            border-top: none;
                        }
                    }
                }
            }
        }
    }

    &.DarkMode {
        .Table {
            color: $white;

            & > .FixedHeadWrap > .Row:not(.TableHeader),
            & > .Row:not(.TableHeader) {
                > .Col {
                    color: $white;
                }

                &:hover,
                &:hover.active {
                    color: $black;

                    > .Col {
                        color: $black;
                    }
                }
            }

            > .FixedHeadWrap > .TableHeader.Row > .Col > .HeadItem,
            > .TableHeader.Row > .Col > .HeadItem {
                color: $white;
            }

            &:not(.TableNoRowHover) {
                > .Row {
                    &:not(.TableHeader) {
                        &.active {
                            > .Col {
                                color: $black;
                            }
                        }
                    }
                }
            }

            .Row:not(.TableHeader) > .Col:not(:first-child) {
                border-left-color: transparent;
            }
        }
    }
}

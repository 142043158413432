@import 'core';

.AppCore .DataGrid {
    .DataGridItem {
        padding: 16px 20px 12px 20px;
        border-right: 1px solid $grey-light-1;
        border-bottom: 1px solid $grey-light-1;

        &:hover,
        &.active {
            background-color: $violet-light;
        }
    }

    > .Row > .Col:last-child {
        .DataGridItem {
            border-right: none;
        }
    }

    > .Row:last-child {
        .DataGridItem {
            border-bottom: none;
        }
    }
}

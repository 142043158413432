@import 'bootstrap-scss/functions';
@import 'bootstrap-scss/variables';
@import 'bootstrap-scss/mixins';

@import 'bootstrap-scss/reboot';

@import '../core/Typography';
@import '../core/Colors';
@import '../core/Spacing';
@import '../core/Responsive';
@import '../core/Flex';

html,
body,
#root,
.AppCore:not(#app-portal-container) {
    width: 100%;
    height: auto;
    min-height: 100%;
}

.AppCore.DarkMode {
    max-height: none;
}

label {
    margin-bottom: 0;
}

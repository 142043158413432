.AppCore {
    .HoursColumn {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;

        .hour {
            display: flex;
            justify-content: center;
            flex-shrink: 0;
        }
    }
}

@import 'core';

.AppCore {
    .RadioGroup {
        outline: none;

        .InputBaseWrapperInner {
            box-shadow: none;
            outline: none;
        }

        &.InputBaseDisabledWrapper {
            .Radio,
            .Radio .RadioLabel {
                pointer-events: none;
                cursor: default;
            }
        }
    }
}

@import 'core';

.AppCore {
    .DatePicker {
        position: relative;
        width: 100%;
        padding: 7px 6px;
    }

    .DatePickerCalendar {
        position: relative;
        user-select: none;
    }

    .DatePickerCalendarHeader {
        font-weight: 500;
        padding: 12px 7px 9px 7px;
        text-align: center;
    }

    .DatePickerCalendarHeaderClickable {
        cursor: pointer;
    }

    .DatePickerCalendarBody {
        width: 100%;

        th,
        td {
            padding-top: 1px;
            padding-bottom: 1px;
            text-align: center;
            width: calc(100% / 7);
            font-size: 16px;
        }

        th {
            font-weight: normal;
            text-transform: uppercase;
            color: var(--content-tertiary);
        }
    }

    .DatePickerCalendarDay {
        color: var(--content-primary);
        line-height: 24px;
        cursor: pointer;

        > span {
            display: block;
            margin: 0 auto;
            width: 24px;
            height: 24px;
            border-radius: 50%;
        }

        &:focus,
        &:hover {
            outline: none;
            color: inherit;

            > span {
                background-color: var(--fill-accent-tertiary) !important;
                color: var(--content-primary);
            }
        }
    }

    .DatePickerCalendarDay,
    .DatePickerCalendarNoDay {
        color: inherit;
    }

    .DatePickerCalendarCurrentDay {
        > span {
            background: var(--fill-tertiary);
        }
    }

    .DatePickerCalendarOutsideDay {
        color: var(--content-quaternary);
        pointer-events: none;
    }

    .DatePickerCalendarSelectedDay {
        color: var(--content-inverse);
        > span {
            background-color: var(--fill-accent) !important;
            color: var(--content-inverse);
        }
        &:focus,
        &:hover {
            outline: none;
            color: inherit;

            > span {
                background-color: var(--fill-accent) !important;
                color: var(--content-inverse);
                font-weight: 500;
            }
        }

        &.DatePickerCalendarSelectedDayPrevToo {
            > span {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &.DatePickerCalendarSelectedDayNextToo {
            > span {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    .DatePickerPrevNext {
        color: inherit;
        position: absolute;
        top: 19px;
        left: 20px;
        right: 20px;
        z-index: 10;
    }

    .DatePickerPrev,
    .DatePickerNext {
        position: absolute;
        top: 0;
        border: none;
        background: transparent;
        padding: 0;

        opacity: 0.7;

        &:hover,
        &:focus {
            outline: none;
            opacity: 1;
        }
    }

    .DatePickerPrev {
        left: 0;
    }

    .DatePickerNext {
        right: 0;
    }

    &.DarkMode {
        .DatePickerCalendarDay {
            .BG-Primary-Violet {
                background-color: $primary-blue !important;
            }

            > span {
                color: $white;
            }

            &.DatePickerCalendarOutsideDay {
                span {
                    color: $grey-dark-3;
                }
            }

            &.DatePickerCalendarCurrentDay,
            &:focus,
            &:hover {
                span {
                    background-color: $yellow-dark !important;
                    color: $black;
                }
            }
        }

        .DatePickerCalendarNoDay {
            td {
                color: $white;
            }
        }

        .DatePickerCalendarHeaderClickable {
            color: $white;
        }

        .DatePickerCalendarBody th {
            color: $white;
        }

        .DatePickerPrev,
        .DatePickerNext {
            .Icon {
                color: $white;
            }
        }
    }
}

@import 'core';

.AppCore {
    .CmsPageBlock {
        margin-bottom: 20px;
    }

    .CmsPageRichText {
        position: relative;

        > p:first-child:last-child {
            padding-bottom: 0;
        }
    }

    .CmsPageImage {
        width: 100%;
    }

    .CmsPageTable {
        width: 100%;
        border-right: 1px solid $grey-dark-1;
        border-bottom: 1px solid $grey-dark-1;

        td {
            padding: 10px;
            vertical-align: top;
            border-left: 1px solid $grey-dark-1;
            border-top: 1px solid $grey-dark-1;
        }
    }

    .CmsContentWrapper {
        display: block;
    }
}

@import 'core';

.AppCore {
    .VideoCallTriggerIconWrap {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;

        .RegistrationStatus {
            display: block;
            position: absolute;
            top: 1px;
            right: 5px;
            width: 8px;
            height: 8px;
            border-radius: 50%;

            &.Registered {
                background-color: $primary-green;
            }

            &.Registering {
                background-color: $primary-yellow;
            }
        }
    }

    .VideoCallInfoPanel {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 4000;

        > .Card {
            padding: 16px;
            width: 384px;
        }
    }

    .VideoCallContainer {
        position: absolute;
        top: 0;
        left: calc(50% + ($nav-width) / 2);
        transition: left 0.2s;
        right: 50%;
        z-index: 19999;
        display: flex;
        justify-content: center;

        @include media-breakpoint-down(tablet) {
            left: 50%;
            position: fixed;
        }

        &.NightHatchV1 {
            left: $nav-width;
        }

        .VideoCallControls {
            margin-top: calc(-1 * (50px + 16px + 32px + 277px));
            transition: margin-top 0.2s;
            width: calc(277px + 32px);
            height: calc(50px + 16px + 32px + 277px);
            border-top-left-radius: var(--corner-radius-no-radius);
            border-top-right-radius: var(--corner-radius-no-radius);
            padding: 16px;

            &.VideoCallControlsVisible,
            &.VideoCallVisible.VideoCallWithPIP {
                margin-top: calc(-1 * (277px + 16px + 1px));
            }

            &.VideoCallVisible:not(.VideoCallWithPIP) {
                margin-top: -1px;
            }

            video {
                display: block;
                width: 277px;
                height: 277px;
                margin-bottom: 16px;
            }

            .DeviceName {
                display: block;
                width: 100%;
                height: 1em;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        @keyframes shakeButtonAnimation {
            0% {
                transform: rotate(0);
            }

            2% {
                transform: rotate(20deg);
            }

            4% {
                transform: rotate(0);
            }

            5% {
                transform: rotate(-20deg);
            }

            6% {
                transform: rotate(0);
            }

            10% {
                transform: rotate(20deg);
            }

            12% {
                transform: rotate(0);
            }

            14% {
                transform: rotate(-20deg);
            }

            16% {
                transform: rotate(0);
            }

            18% {
                transform: rotate(20deg);
            }

            20% {
                transform: rotate(0);
            }

            22% {
                transform: rotate(-20deg);
            }

            24% {
                transform: rotate(0);
            }

            100% {
                transform: rotate(0);
            }
        }

        .ShakeButton {
            animation: shakeButtonAnimation 1s ease 0s infinite normal forwards;
        }
    }

    .SidebarNavigation.DetailedViewOpened + .MainNavigation + .VideoCallInfoPanel + .VideoCallContainer {
        left: calc(50% + ($nav-width + $nav-detailed-view-width) / 2);

        &.NightHatchV1 {
            left: $nav-width + $nav-detailed-view-width;
        }

        @include media-breakpoint-down(tablet) {
            left: 50%;
        }
    }
}

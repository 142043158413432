@import 'core';

.AppCore {
    .SortableGrid {
        .SortableGridItem {
            position: relative;
            touch-action: none;
            cursor: move;
            z-index: 1;
        }

        .DraggingSortableGridItem {
            color: inherit;
        }

        .DraggedSortableGridItem {
            z-index: 2;
        }
    }
}

@import 'core';

.AppCore {
    .Pagination {
        > span {
            display: flex;
            align-items: center;

            > .SelectWrapper:first-child {
                width: auto;
                margin-right: 19px;
            }
        }
        .Button.tertiary {
            border-color: var(--stroke-strong);
            background-color: var(--fill-elevated-primary);

            &.disabled {
                border-color: var(--stroke-strong);
                opacity: 1;
                color: var(--content-quaternary);
                .Icon {
                    color: var(--content-quaternary);
                }
            }
        }
        .Button.plain {
            padding: 0px;
        }
        .PrevPage {
            margin-left: 9px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .NextPage {
            margin-right: 9px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .PaginationInfoText {
        margin-right: 19px;
    }

    .PaginationPages {
        display: flex;
        align-items: center;
        list-style-type: none;
        border-top: 1px solid var(--stroke-strong);
        border-bottom: 1px solid var(--stroke-strong);
        margin-bottom: 0;
        padding: 13px 11px;
        background-color: var(--fill-elevated-primary);
        &.plain {
            border-top: none;
            border-bottom: none;
            padding: 0;
            background-color: transparent;
        }
        a,
        .PaginationEllipsis {
            display: block;
            min-width: 40px;
            text-align: center;
            border-bottom: none;
            outline: none;
            color: var(--content-tertiary);
            &:hover,
            &:focus,
            &.active {
                span {
                    color: var(--content-accent);
                }
            }
        }
    }
}

@import 'core';

.AppCore .FileInput {
    display: block;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

@import 'core';

.AppCore {
    .TimePicker {
        padding: 7px 11px;

        .InputBaseLabel {
            font-weight: normal;
            color: $grey-dark-2;
            font-size: 14px;
            margin-bottom: -4px;
            user-select: none;
        }
    }
}

@import 'core';

.AppCore .LandingPage {
    .HeroImageWrap {
        margin-top: 141px;
        margin-left: 31px;

        .HeroImage {
            width: 100%;
            height: 100%;
            min-height: 657px;
            background: url('/static/images/landing-hero-image.png') no-repeat 0 0 transparent;
            background-size: contain;
        }
    }

    .Logo {
        display: block;
        width: 215px;
        height: 115px;
        background: url('/static/images/logo-3.svg?v=1.0') no-repeat 0 0 transparent;
        background-size: contain;
        margin-top: -9px;
        margin-left: -13px;
        border-bottom: none;
    }

    .PageTitle {
        margin-top: 47px;
    }

    .PageDescription {
        margin-top: 25px;
        margin-bottom: 13px;
        max-width: 457px;
    }

    .FooterLinks {
        text-align: right;

        @include media-breakpoint-up(mobile) {
            margin-top: -45px;
        }

        > * {
            display: inline-block;
            margin: 0 9px;
            color: $grey-dark-2;
            border-bottom: none;
        }
    }
}

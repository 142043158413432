@import 'core';

.AppCore {
    .Icon {
        display: inline-block;
        color: var(--content-primary);
        &.xxxl {
            width: 84px;
            height: 84px;
            line-height: 84px;
        }
        &.xxl {
            width: 48px;
            height: 48px;
            line-height: 48px;
        }
        &.xl {
            width: 40px;
            height: 40px;
            line-height: 40px;
        }
        &.l {
            width: 32px;
            height: 32px;
            line-height: 32px;
        }

        &.m {
            width: 24px;
            height: 24px;
            line-height: 24px;
        }

        &.s {
            width: 16px;
            height: 16px;
            line-height: 16px;
        }
        &.xs {
            width: 14px;
            height: 14px;
            line-height: 14px;
        }
        &.xxs {
            width: 12px;
            height: 12px;
            line-height: 12px;
        }

        svg {
            vertical-align: top;
            fill: currentColor;
            width: 100%;
            height: 100%;
        }

        &.Circle {
            border-radius: 50%;
            font-size: 12px;
            color: $white;
            font-weight: 600;
            text-align: center;
            font-style: normal;
        }
    }

    .BadgeIcon {
        display: inline-block;
        border-radius: 50%;

        &.xl {
            width: 84px;
            height: 84px;
        }

        &.l {
            width: 44px;
            height: 44px;
        }

        &.m {
            width: 32px;
            height: 32px;
        }

        &.s {
            width: 20px;
            height: 20px;
        }
    }

    .IconGroup {
        text-align: right;
        white-space: nowrap;

        > * {
            display: inline-block;
            vertical-align: middle;
            margin-right: 9px;

            &:last-child {
                margin-right: 0;
            }
        }

        > .Tooltip {
            line-height: 22px !important;
        }

        .Icon {
            width: 22px !important;
            height: 22px !important;
            line-height: 22px !important;

            &.Circle {
                width: 18px !important;
                height: 18px !important;
                line-height: 18px !important;
            }
        }
    }
}

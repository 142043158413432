@import 'core';

.AppCore {
    .LabelWithPills {
        position: relative;
        display: inline-block;
        width: 100%;
        border: 1px solid $grey-light-3;
        border-radius: 8px;
        padding: 4px 6px 0 6px;
        margin-bottom: 12px;

        &.NoPills {
            padding-bottom: 4px;
        }

        .Tooltip {
            vertical-align: bottom;
            width: 100%;
            max-width: 100%;
        }
    }

    .LabelWithPillsPills {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 3px -8px 3px;

        .Pill {
            margin: 2px;
            font-size: 13px;
            line-height: 16px;
        }
    }

    .LabelWithPills.AlignRight .LabelWithPillsPills {
        text-align: right;
    }
}

@import 'core';

.AppCore {
    .container.HeaderMenu {
        width: auto;
        height: 80px;
        overflow: hidden;
        padding: 0 16px 0 ($nav-width + 30px);
        transition: padding 0.2s;
    }

    @include media-breakpoint-up(mobile) {
        .SidebarNavigation.DetailedViewOpened {
            & + .MainNavigation {
                .container.HeaderMenu {
                    padding-left: $nav-detailed-view-width + $nav-width + 30px;
                }
            }
        }
    }

    .HeaderMenu {
        @include media-breakpoint-up(mobile) {
            &:not(.HeaderMenuMobile) {
                background-color: var(--fill-elevated-primary);
                border-bottom: 1px solid var(--stroke-strong);
            }
        }

        > .Row {
            flex-wrap: wrap;
            align-items: center;
        }

        .DropdownHeader > .Button.secondary {
            background: transparent;
            padding: 10px;
            &:hover,
            &:focus {
                background: var(--fill-elevated-tertiary);
            }
        }

        .DropdownBody > .Card > .Menu {
            min-width: 213px;
        }
    }

    .DropdownBody .MenuAnchor {
        display: block;
        padding: 14px 18px 16px;
        cursor: pointer;
        font-size: 18px;
        line-height: 22px;
        text-decoration: none;
        border-bottom: none;
        &:hover,
        &:focus {
            background: $grey-light-2;
        }
    }

    .HeaderMenu.HeaderMenuMobile {
        position: fixed;
        z-index: 4000;
        top: 10px;
        right: 8px;
        display: flex;

        > .Dropdown > .DropdownHeader {
            line-height: 36px;
            border: 1px solid transparent;

            &:hover,
            &:focus {
                background: transparent;
                border-color: $grey-dark-1;
            }

            > .Icon {
                width: 32px;
                height: 32px;
                line-height: 32px;
            }
        }
    }

    .SidebarNavigation + .MainNavigation {
        .HeaderMenu.HeaderMenuMobile {
            > .Dropdown > .DropdownHeader > .Button.secondary {
                color: $white;
                background-color: transparent;
                &:hover,
                &:focus {
                    border-color: $white;
                    border: 1px solid;
                }

                > .Icon {
                    color: $white !important;
                }

                > .IconContainer {
                    margin-right: 0px;
                    margin-left: 0px;
                    width: 32px;
                    height: 32px;
                    line-height: 32px;

                    > .CircleOutlineIcon {
                        > .Icon {
                            width: 36px;
                            height: 36px;
                            line-height: 36px;
                        }

                        > .InsideCircle {
                            font-size: 15px;
                            line-height: 36px;
                            top: -2px;
                            left: -2px;
                            width: 36px;
                        }
                    }
                }
            }
        }
    }

    &.DarkMode {
        .DropdownBody .MenuAnchor {
            color: $white;

            &:hover {
                background-color: $grey-light-3;
                color: $primary-blue;
            }
        }

        .container.HeaderMenu {
            .DropdownHeader > .Button.secondary,
            .SteamerIconContainer {
                color: $white !important;

                > * {
                    color: $white !important;
                }

                &:hover,
                &:focus {
                    background-color: $white !important;
                    color: $black !important;

                    > * {
                        color: $black !important;
                    }
                }
            }

            .SteamerIconContainer {
                svg {
                    path {
                        fill: $white !important;
                    }
                }

                &:hover,
                &:focus {
                    svg {
                        path {
                            fill: $black !important;
                        }
                    }
                }
            }
        }
    }
}

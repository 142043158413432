@import 'core';

.AppCore {
    .RTControls {
        .Control {
            display: inline-block;
            vertical-align: top;
        }

        .FontControl,
        .FontSizeControl {
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 6px;
                right: -1px;
                width: 1px;
                height: 16px;
                background: $grey-light-1;
            }
        }

        .FontControl {
            width: 128px;
        }

        .FontSizeControl {
            width: 68px;

            .DropdownHeader {
                .InputBaseWrapperInner {
                    box-shadow: none;
                }

                .Input {
                    padding: 2px 24px 2px 0;
                    border: none;
                }

                &:after {
                    right: 13px;
                }
            }
        }

        .ColorControl {
            padding-top: 1px;
            padding-bottom: 1px;

            > .InputBaseWrapperInner {
                margin-top: 0;
            }
        }

        .TextControl {
            textarea {
                border-style: dashed;
            }
        }
    }

    .RTControlsFontControl {
        width: 210px;

        .Input {
            padding: 7px 11px;
        }

        .MenuWithSearch > div:first-child {
            padding: 0 8px !important;
        }

        .MenuItem {
            display: block;
            padding: 0;

            > label {
                padding: 7px 8px 6px;
            }
        }
    }

    .RTControlsFontControl {
        width: 210px;
        margin-top: 3px;
    }
}

@import 'core';

.AppCore {
    .Modal,
    .Modal:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .Modal {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
            content: '';
            display: block;
            background: $grey-dark-1;
            opacity: 0.3;
            cursor: pointer;
        }
    }

    .ModalWrap {
        position: absolute;
        top: 10%;
        right: 10%;
        bottom: 10%;
        left: 10%;
        display: flex;
        flex-direction: column;
        padding: 8px 16px;
        border-radius: var(--corner-radius-lg);
    }

    .ModalHeader {
        &:not(.Empty) {
            padding: 8px 16px;
            background: var(--fill-elevated-secondary);
            border-bottom: 1px solid var(--stroke-default);
            border-top-left-radius: var(--corner-radius-lg);
            border-top-right-radius: var(--corner-radius-lg);
        }
    }

    .ModalBody {
        padding: 32px;
    }

    .ModalFooter {
        padding: 16px;
        background: var(--fill-elevated-secondary);
        border-top: 1px solid var(--stroke-default);
        border-bottom-left-radius: var(--corner-radius-lg);
        border-bottom-right-radius: var(--corner-radius-lg);
    }

    .ModalCloseAction {
        position: absolute;
        top: 12px;
        right: 12px;
        border-bottom: none;

        > .Icon {
            color: var(--content-tertiary);
        }

        &:hover {
            > .Icon {
                color: var(--content-primary);
            }
        }
    }
}

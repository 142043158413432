@import 'core';

.AppCore {
    .CalendarSchedule {
        height: 100%;
        display: flex;
        max-height: 920px;
        flex-direction: column;

        .HeaderCurrentViewDate {
            font-weight: 400;
            font-size: 18px;
            color: $grey-dark-1;
        }

        .CalendarBody {
            flex-grow: 1;
            overflow: hidden;
            display: flex;
            &.disabled {
                opacity: 0.34;
            }
        }
    }
}

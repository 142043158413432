@import 'core';

.AppCore {
    .ColorPickerWrap {
        outline: none;

        .InputBaseLabel,
        .InputBaseWrapperInner {
            display: inline-block;
            vertical-align: top;
        }

        .InputBaseLabel {
            margin-bottom: 0;
            margin-right: 19px;
            vertical-align: top;
        }

        .InputBaseWrapperInner {
            width: auto;
            box-shadow: none;
            margin-top: 2px;
            height: 22px;
        }
    }

    .ColorPicker {
        display: block;

        .Dropdown .DropdownHeader {
            outline: none;
            padding: 0 7px;

            &:after {
                right: 9px;
                bottom: 8px;
            }

            &:focus {
                background: $grey-light-3;
                border-radius: 3px;
            }
        }
    }

    .ColorPickerDropdownBody {
        padding-top: 7px;
        padding-bottom: 7px;
        max-width: 242px;

        .Card {
            width: 300px;
            padding: 0;
        }
    }

    .ColorPickerDisabled {
        opacity: 0.7;
    }

    .ColorPickerHeader {
        display: block;
        padding: 0 18px 0 0;
        cursor: pointer;
    }

    .ColorPickerHeaderColorBox {
        display: inline-block;
        vertical-align: top;
        margin-top: 4px;
        width: 15px;
        height: 15px;
        border: 1px solid transparent;
    }

    .ColorPickerPresetsColorBoxTransparent {
        position: relative;
        border-color: $grey-dark-1 !important;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;

            background: linear-gradient(
                to top left,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) calc(50% - 1.6px),
                #cc1f51 50%,
                rgba(0, 0, 0, 0) calc(50% + 1.6px),
                rgba(0, 0, 0, 0) 100%
            );
        }
    }

    .InputBaseDisabledWrapper {
        .ColorPickerHeader {
            cursor: default;

            &:after {
                border-color: $grey-light-1 transparent transparent transparent;
            }
        }
    }

    .InputBaseWithErrorWrapper {
        .ColorPickerHeader {
            &:after {
                border-color: $color-error transparent transparent transparent;
            }
        }

        .ColorPickerHeaderColorBox {
            border-color: $color-error !important;
        }
    }

    .InputBaseDisabledWrapper.InputBaseWithErrorWrapper {
        .ColorPickerHeader {
            color: red;
        }

        .ColorPickerHeaderColorBox {
            border-color: $color-error-light;
        }
    }

    .ColorPickerBody {
        min-width: 200px;
    }

    .ColorPickerBoardWrap {
        position: relative;
        left: 0;
        display: block;
        width: 100%;
        padding-top: 100%;
    }

    .ColorPickerBoard {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        touch-action: none;

        .ColorPickerBoardBackground,
        .ColorPickerBoardBackground > div {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .ColorPickerBoardBackground {
            background: linear-gradient(to right, rgb(255, 255, 255), rgba(255, 255, 255, 0));
        }

        .ColorPickerBoardBackground > div {
            background: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
        }
    }

    .ColorPickerBoardIndicator {
        position: absolute;
        display: block;
        width: 8px;
        height: 8px;
        margin-left: -4px;
        margin-top: -4px;
        border-radius: 50%;
        border: 1px solid $black;
        transition: border-color 0.2s;

        &:focus {
            outline: none;
            width: 12px;
            height: 12px;
            margin-left: -6px;
            margin-top: -6px;
            border-width: 2px;
        }
    }

    .ColorPickerRibbon {
        position: relative;
        width: 100%;
        height: 24px;
        overflow: hidden;
        touch-action: none;
        background: linear-gradient(
            to right,
            rgb(255, 0, 0) 0%,
            rgb(255, 255, 0) 17%,
            rgb(0, 255, 0) 33%,
            rgb(0, 255, 255) 50%,
            rgb(0, 0, 255) 67%,
            rgb(255, 0, 255) 83%,
            rgb(255, 0, 0) 100%
        );
    }

    .ColorPickerRibbonIndicator {
        position: absolute;
        display: block;
        width: 6px;
        height: 100%;
        margin-left: -3px;
        border-radius: 2px;
        border: 1px solid $grey-dark-1;

        &:focus {
            outline: none;
            border-width: 2px;
            background: $white;
        }
    }

    .ColorPickerParams {
        padding: 7px;
        background: transparent;

        input[type='text'],
        input[type='number'] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }

        input {
            width: 100%;
            font-size: 13px;
            border: 1px solid $grey-dark-3;
            padding-left: 2px;
            margin-bottom: 1px;
            border-left-width: 0;
            text-align: center;
            border-radius: 0;

            &:focus {
                outline: none;
                border-bottom: 2px solid $grey-dark-2;
                margin-bottom: 0;
            }
        }

        label {
            display: block;
            text-align: center;
            font-size: 13px;
        }

        .Col:first-child {
            input {
                border-left-width: 1px;
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
                text-align: left;
            }

            label {
                text-align: left;
                margin-left: 4px;
            }
        }

        .Col:last-child {
            input {
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
            }
        }
    }

    .ColorPickerPresets {
        display: flex;
        flex-wrap: wrap;
        padding: 0 7px 7px 7px;
        margin: -2px -3px;
    }

    .ColorPickerPresetsColorBox {
        display: block;
        width: 15px;
        height: 15px;
        border: 1px solid transparent;
        margin: 2px 3px;
        cursor: pointer;
        border-radius: 2px;
    }

    .ColorPickerEyedropperButton.Button.xxs {
        padding: 4px;

        .Icon {
            width: 16px;
            height: 16px;
            line-height: 16px;
            color: $grey-dark-1;
        }
    }
}

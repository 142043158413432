@import 'core';

.AppCore {
    .PublicAnnouncementsWrapper {
        &.PublicAnnouncementWarrapperPopUp {
            min-height: 100vh;
            width: 420px;
            box-shadow:
                0 4px 8px rgba(0, 0, 0, 0.2),
                0 6px 20px rgba(0, 0, 0, 0.19);
            animation: fadeIn 0.3s ease-out;
            background-color: var(--fill-base);
            margin: 0;
            margin-left: auto;
        }

        .PublicAnnouncementsItems {
            padding-top: 0 !important;
            .PublicAnnouncementItemClickable {
                cursor: pointer;
            }
            .PublicAnnouncementItem {
                margin-bottom: 32px;
                border-bottom: 1px solid var(--stroke-strong);

                &:last-child {
                    margin-bottom: 0;
                    border-bottom: none;
                    > .Card {
                        padding: 12px;
                    }
                }
                .Card {
                    border: none;
                    background-color: transparent;
                    border-radius: 0;
                    padding: var(--padding-margin-sm, 12px) var(--padding-margin-sm, 12px) var(--padding-margin-xl, 32px)
                        var(--padding-margin-sm, 12px);
                }

                &.UnreadAnnouncement {
                    background-color: var(--fill-base);
                    border-radius: var(--corner-radius-lg) var(--corner-radius-lg) 0 0;
                }
            }
        }
        .SteamerWidget {
            background-color: var(--fill-base-secondary);
        }
        .SteamerPageScrollableList {
            overflow-y: auto;
            max-height: calc(100vh - 250px);
            padding-bottom: 30px;
            .PublicAnnouncementItem {
                max-width: calc(100% - 20px) !important;
            }
        }
        .SteamerScrollableList {
            overflow-y: auto;
            max-height: calc(100vh - 100px);
            padding: 24px 12px 24px 12px;
        }

        .PublicAnnouncementItemHeader {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 4px;
            .Icon {
                color: var(--content-tertiary);
            }
        }
        .PublicAnnouncementItemRead {
            .Icon {
                color: var(--content-success);
            }

            color: var(--content-success);
        }

        .PublicAnnouncementTags {
            &.AllowClick {
                > div {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            > div {
                cursor: default;
                display: inline-block;
                > span {
                    text-transform: uppercase;
                }
            }

            &.SidebarFiterTags {
                margin-top: 20px;
                > div {
                    width: 100%;
                    border-bottom: 1px solid $grey-light-2;
                    position: relative;
                    padding: 2px 40px 2px 0;

                    &:after {
                        content: '';
                        position: absolute;
                        height: 15px;
                        width: 15px;
                        border: 1px solid $primary-blue;
                        border-radius: 50%;
                        display: inline-block;
                        right: 0;
                        top: 50%;
                        transform: translate(0, -50%);
                    }

                    > .Pill {
                        margin-top: 7px;
                    }
                    &.ActiveFilterTag {
                        border-bottom: 1px solid $primary-blue;

                        &:before {
                            content: '';
                            position: absolute;
                            height: 9px;
                            width: 9px;
                            background-color: $primary-blue;
                            border-radius: 50%;
                            display: inline-block;
                            right: 3px;
                            top: 50%;
                            transform: translate(0, -50%);
                        }
                    }
                }
            }
        }

        &.WithFilters {
            .PublicAnnouncementsItems {
                .PublicAnnouncementItem {
                    max-width: 600px;
                }
            }
            .PublicAnnouncementsFilters {
                padding-top: 11px !important;
                display: inline-block;

                > div {
                    max-width: 300px;
                }
            }
        }
        .PublicAnnouncementListHeader {
            display: flex;
            background-color: var(--fill-accent);
            align-items: center;
            padding: 24px;
            color: var(--content-inverse);
            .typography {
                color: inherit;
            }
            .Icon {
                color: inherit;
            }
            .Button {
                width: fit-content;
                padding: 8px 12px;
                .IconContainer {
                    width: 18px;
                }
            }
        }
        &.IsWidget {
            border-radius: 4px;
            border: 1px solid $grey-light-1;
            .SteamerScrollableList {
                padding: 12px 6px;
                padding-bottom: 0px;
            }
            .PublicAnnouncementsItems {
                padding-bottom: 0 !important;
                .PublicAnnouncementItem {
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0;
                        border-bottom: none;
                    }
                    > .Card {
                        padding-bottom: 20px;
                        // TODO: FIX THIS WEIRD logic on the height of a cms content
                        .CmsContentWrapper {
                            max-height: 46px;
                            overflow: hidden;

                            .CmsPageBlock {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@import 'core';

.AppCore {
    .CalendarEvent {
        position: absolute;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 7px;
        &.canceled {
            text-decoration: line-through;
            opacity: 0.5;
        }
        &.completed {
        }
        .eventText,
        .eventTime {
            font-size: 13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
            line-height: 15px;
        }

        .eventText {
            font-size: 15px;
            font-weight: 500;
        }
        .eventTime {
            font-size: 13px;
            font-weight: 400;
        }
    }
}

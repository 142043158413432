$chip-padding: (
    xxs: 0px 2px,
    xs: 2px 4px,
    sm: 4px 8px,
    md: 4px 8px,
);

$chip-border-radius: (
    xs: 2px,
    sm: 4px,
    rounded: 96px,
);

.Pill {
    display: inline-flex;
    align-items: center;
    border: 1px solid transparent;
    cursor: inherit;
    &.typography {
        font-weight: 500;
    }

    label {
        cursor: inherit;
    }

    .Icon {
        color: inherit !important;
    }

    &.clickable {
        cursor: pointer;

        &:hover {
            background-color: var(--fill-elevated-tertiary);
        }
    }

    &.xxs {
        padding: map-get($chip-padding, xxs);
    }

    &.xs {
        padding: map-get($chip-padding, xs);
    }

    &.sm {
        padding: map-get($chip-padding, sm);
    }

    &.md {
        padding: map-get($chip-padding, md);
    }

    // Border radius mixin
    @mixin radius-style($radius) {
        border-radius: map-get($chip-border-radius, $radius);
        padding-left: 12px;
        padding-right: 12px;
    }

    &.radius-xs {
        @include radius-style(xs);
    }

    &.radius-sm {
        @include radius-style(sm);
    }

    &.radius-rounded {
        @include radius-style(rounded);
    }
    @each $color in (primary, secondary, tertiary, accent, success, error, s1, s2, s3, s4, s5) {
        &.#{$color} {
            background-color: var(--fill-#{$color});
            @if $color == tertiary {
                color: var(--content-primary);

                .typography {
                    color: var(--content-primary);
                }
            } @else {
                color: var(--content-inverse);
                .typography {
                    color: var(--content-inverse);
                }
            }

            &.outlined {
                background-color: var(--fill-#{$color}-weak);
                border-color: var(--stroke-#{$color}-on-weak-fill);
                color: var(--content-#{$color}-on-weak-fill);
                .typography {
                    color: var(--content-#{$color}-on-weak-fill);
                    font-weight: 500;
                }
            }

            &.text-only {
                background-color: transparent;
                border-color: transparent;
                color: var(--content-#{$color});
                font-weight: 500;
                .typography {
                    color: var(--content-primary);
                    font-weight: 500;
                }
            }
        }
    }

    .chip-icon {
        margin-right: 8px;
    }

    .chip-delete {
        margin-left: 8px;
        cursor: pointer;
    }
}

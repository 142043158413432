@import 'core';

.AppCore {
    .SelectWrapper {
        position: relative;
        display: block;
        width: 100%;

        .InputBaseWrapperInner {
            outline: none;
        }

        .SelectHeaderLabel,
        .SelectHeaderWithPlaceholder,
        .SelectHeaderWithClearAction {
            text-decoration: none;
            font-weight: 400;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }

        .SelectHeaderWithPlaceholder {
            color: $grey-dark-2;

            &:hover,
            &:focus {
                color: $grey-dark-2;
            }
        }

        .InputBaseWrapperInner:focus .SelectHeaderWithPlaceholder {
            color: $grey-dark-1;
        }

        &.SelectWithSmallHeader {
            .DropdownHeader {
                .InputBaseWrapperInner {
                    box-shadow: none;

                    &:focus {
                        background: $grey-light-3;
                        border-radius: 3px;

                        .Input {
                            background: transparent;
                        }
                    }

                    &:after {
                        content: '';
                        display: block;
                        padding: 0;
                        position: absolute;
                        top: 50%;
                        margin-top: -1px;
                        right: 13px;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 4px 3.5px 0 3.5px;
                        border-color: $grey-dark-2 transparent transparent transparent;
                    }
                }

                .Input {
                    border: none;
                    display: block;
                    padding: 2px 34px 2px 13px;
                    cursor: pointer;
                }

                .InputIconRight:not(.ClearAction) {
                    display: none;
                }

                > .InputBaseWrapper.WithClearAction .InputIconRight.ClearAction {
                    right: 22px;
                    padding: 0 6px;

                    .Icon {
                        margin-top: 2px;
                        width: 16px;
                        height: 16px;
                        line-height: 16px;
                    }
                }

                > .InputBaseWrapper.with-icon-right.WithClearAction .InputBaseWrapperInner .Input {
                    padding-right: 50px;
                }

                .InputBaseWithErrorWrapper {
                    .Input {
                        color: $color-error;
                    }
                }

                &:after {
                    display: none;
                }
            }

            .InputBaseWrapperInner:focus {
                .DropdownHeaderWithArrow:after {
                    border-color: $primary-blue transparent transparent transparent;
                }
            }
        }

        &.SelectWithLargeHeader {
            .DropdownHeader {
                .Input {
                    padding-top: 11px;
                    padding-bottom: 11px;
                    padding-left: 15px;
                }
            }
        }

        .InputBaseDisabledWrapper {
            .SelectHeaderLabel {
                color: $grey-dark-1;
            }

            .Input {
                pointer-events: none;
                cursor: default;
            }
        }

        > .DropdownHeader {
            outline: none;

            > label {
                outline: none;
            }

            > .InputBaseWrapper:not(.InputBaseDisabledWrapper) {
                > .InputBaseWrapperInner {
                    cursor: pointer;
                }
            }

            > .InputBaseWrapper.WithClearAction {
                .InputIconRight.ClearAction {
                    right: 32px;
                }
            }

            > .InputBaseWrapper.with-icon-right.WithClearAction .InputBaseWrapperInner .Input {
                padding-right: 72px;
            }
        }

        select {
            display: none;
        }
    }

    .ClearAction {
        border-bottom: none;
    }

    .SelectWrapper:focus,
    .SelectWrapper.DropdownOpened,
    .SelectWrapper .InputBaseWrapperInner:focus {
        .Input {
            border-color: $grey-dark-2;
            text-decoration: none;
            color: $grey-dark-1;

            &:focus {
                border-color: $grey-dark-1;
            }
        }

        .SelectHeaderWithPlaceholder {
            color: $grey-dark-2;
        }
    }

    .SelectHeaderLabel {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .Icon {
            width: 18px;
            height: 18px;
            vertical-align: top;
            margin-top: 2px;

            &.Circle {
                line-height: 18px;
            }
        }
    }

    .SelectDropdownBody {
        padding-top: 11px;
        padding-bottom: 11px;

        > .Card > .Menu {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .SelectAll {
            position: absolute;
            top: 20px;
            left: 20px;

            &.SelectAllWithSearch {
                top: 80px;
            }

            > a {
                border: none;
            }
        }
    }

    &.DarkMode {
        .SelectDropdownBody .Card {
            border: 1px solid $grey-dark-3;
        }
    }
}

@import 'core';

.AppCore {
    .MonthlyView {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        border: 1px solid $grey-light-1;
        overflow-x: auto;

        .header {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            position: sticky;
            top: 0;
            z-index: 10;
            box-shadow: $eleveted-surface-shadow;
            background-color: white;
            min-width: 560px;
        }

        .dayHeader {
            height: 48px;
            padding: 16px;
            border-bottom: 1px solid $grey-light-1;
            display: flex;
            align-items: center;
            border-left: 1px solid $grey-light-1;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            min-width: 80px;
            &:first-child {
                border-left: none;
            }
        }

        .body {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            min-width: 560px;
        }

        .dayCell {
            border-bottom: 1px solid $grey-light-1;
            border-left: 1px solid $grey-light-1;
            box-sizing: border-box;
            position: relative;
            height: 160px;
            min-width: 80px;
            display: flex;
            flex-direction: column;
            &:first-child {
                border-left: none;
            }

            &:nth-child(7n + 1) {
                border-left: none;
            }

            &:nth-last-child(-n + 7) {
                border-bottom: none;
            }
            &.adjacent {
                opacity: 0.5;
                background-color: rgba(251, 251, 251, 0.5);
            }
        }

        .events {
            flex-grow: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
        .CalendarEvent {
            position: initial;
            margin-bottom: 0.5px;
        }
    }

    .VieMoreDialogCard {
        box-shadow: $pop-up-overlay-shadow;
        border: 1px solid $grey-light-1;
        border-radius: 8px;
        padding: 16px;
        min-width: 320px;
        .CalendarEvent {
            position: initial;
            margin-bottom: 0.5px;
        }
    }

    .ViewMoreEventsContainer {
        margin-top: 8px;
        max-height: 200px;
        overflow-y: auto;
    }
}

@import './colors/brand-color.scss';
@import './colors/dark-mode.scss';
@import './colors/light-mode.scss';
@import './colors/shadows.scss';
@import './colors/sizes.scss';

@import 'core';

body {
    background-color: var(--background-base);
    @media print {
        background-color: $white;
    }
}

//Force backgrounds to show when printing

@media print {
    * {
        -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
        color-adjust: exact !important; /* Firefox 48 – 96 */
        print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
    }
}

@include mediaPrint {
    #main-sidebar {
        display: none;
    }
    .no-print {
        display: none !important;
    }
    .AppCore > .MainNavigation {
        margin-left: 0;
        width: 100%;
    }
}

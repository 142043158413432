@import 'core';

.AppCore {
    .SpinnerValue {
        text-align: center;
        user-select: none;
        padding: 8px;
        border-radius: 4px;
        border: 1px solid var(--stroke-default);
        background: var(--fill-elevated-primary);
        min-width: 36px;
    }

    .SpinnerLabel {
        user-select: none;
        margin-left: 10px;
    }
}

@use 'sass:math';
@import 'core';

.AppCore {
    $card-horizontal-spacing-s: 22px;
    $card-vertical-spacing-s: 11px;

    $card-horizontal-spacing-m: 33px;
    $card-vertical-spacing-m: 15px;

    $card-vertical-spacing-l: 33px;

    .CardItem {
        border-bottom: 1px solid var(--stroke-strong);

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }

        > p {
            margin-bottom: 3px;
        }

        > .InputWrapper {
            margin-bottom: 0;
        }

        &.ElevatedCardItem {
            box-shadow: $eleveted-surface-shadow;
        }
    }

    .Card {
        border: 1px solid var(--stroke-strong);
        border-radius: var(--corner-radius-md);
        background-color: var(--fill-elevated-primary);

        > p {
            margin-bottom: 3px;
        }
    }

    .Card.no-outline {
        border-color: transparent;
    }

    .Card.elevation-1 {
        box-shadow: 0 2px 8px 0 rgba(207, 216, 223, 0.5);
    }

    .Card.ElevatedHover {
        &:hover {
            @extend .elevation-1;
        }
    }

    .Card.v-spacing-none {
        padding-top: 0;
        padding-bottom: 0;
    }

    // s is default vertical spacing too
    .Card,
    .Card.v-spacing-s {
        padding-top: $card-vertical-spacing-s;
        padding-bottom: $card-vertical-spacing-s;

        .CardItem {
            padding-top: $card-vertical-spacing-s;
            padding-bottom: $card-vertical-spacing-s - 2px;

            &:first-child {
                padding-top: 3px;
            }

            &:last-child {
                padding-bottom: 3px;
            }
        }
    }

    .Card.v-spacing-m {
        padding-top: $card-vertical-spacing-m;
        padding-bottom: $card-vertical-spacing-m;

        .CardItem {
            padding-top: $card-vertical-spacing-m;
            padding-bottom: $card-vertical-spacing-m - 2px;

            &:first-child {
                padding-top: 7px;
            }

            &:last-child {
                padding-bottom: 7px;
            }
        }
    }

    .Card.h-spacing-none {
        padding-left: 0;
        padding-right: 0;

        .CardItem {
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }

    // s is default horizontal spacing too
    .Card,
    .Card.h-spacing-s {
        padding-left: $card-horizontal-spacing-s;
        padding-right: $card-horizontal-spacing-s;

        .CardItem {
            margin-left: -$card-horizontal-spacing-s;
            margin-right: -$card-horizontal-spacing-s;
            padding-left: $card-horizontal-spacing-s;
            padding-right: $card-horizontal-spacing-s;
        }

        > .Card,
        > .CardContainer,
        > .CardItem > .Card,
        > .CardItem > .CardContainer {
            margin-left: math.div(-$card-horizontal-spacing-s, 2);
            margin-right: math.div(-$card-horizontal-spacing-s, 2);
        }
    }

    .Card.h-spacing-m {
        padding-left: $card-horizontal-spacing-m;
        padding-right: $card-horizontal-spacing-m;

        .CardItem {
            margin-left: -$card-horizontal-spacing-m;
            margin-right: -$card-horizontal-spacing-m;
            padding-left: $card-horizontal-spacing-m;
            padding-right: $card-horizontal-spacing-m;
        }

        > .Card,
        > .CardContainer,
        > .CardItem > .Card,
        > .CardItem > .CardContainer {
            margin-left: math.div(-$card-horizontal-spacing-m, 2);
            margin-right: math.div(-$card-horizontal-spacing-m, 2);
        }
    }
    .Card.v-spacing-l {
        padding-top: $card-vertical-spacing-l;
        padding-bottom: $card-vertical-spacing-l;

        .CardItem {
            padding-top: $card-vertical-spacing-l;
            padding-bottom: $card-vertical-spacing-l - 2px;

            &:first-child {
                padding-top: 7px;
            }

            &:last-child {
                padding-bottom: 7px;
            }
        }
    }

    .CardContainer {
        padding: 11px;
        border: 1px solid transparent;
        border-radius: 4px;
        background-color: $grey-light-4;
    }

    .CardContainer.outlined {
        border-color: var(--stroke-strong);
    }

    .Card,
    .CardContainer,
    .CardItem {
        > .Card + .Card,
        > .Card + .CardContainer,
        > .CardContainer + .Card,
        > .CardContainer + .CardContainer {
            margin-top: 14px;
        }
    }

    &.DarkMode {
        .Card {
            border: 1px solid transparent;
        }

        .Card.elevation-1,
        .Card.ElevatedHover:hover {
            box-shadow: 0 2px 8px 0 rgba(16, 17, 17, 0.5);
        }
    }
}


@mixin wheelchairMode() {
    @media only screen and (min-width: 1280px) {
        @content;
    }

    .MaticScreen.WheelchairMode,
    .MaticFullScreenDialog.WheelchairMode {
        @content;
    }
}

@mixin mediaPrint {
    @media print {
        @content;
    }
}

@import 'core';

.AppCore {
    .BasicPageHeaderRight {
        position: relative;

        > * {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

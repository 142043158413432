.AppCore {
    .MenuWithSearch {
        .InputWrapper {
            padding: 0 22px;
        }
    }

    .MenuWithSearchExtraHeader {
        display: flex;

        .InputBaseWrapper {
            width: auto;
            flex: 1;
        }
    }
}

@import 'core';

.AppCore {
    input[type='text'],
    input[type='number'],
    input[type='password'],
    input[type='email'],
    input[type='phone'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .Input {
        display: block;
        width: 100%;

        border: 1px solid var(--stroke-strong);
        border-radius: 4px;
        background-color: var(--fill-elevated-primary);
        color: var(--content-primary);

        padding: 11px 16px;

        &.small {
            padding-top: 9px;
            padding-bottom: 9px;
        }

        &:focus,
        &.active {
            border-color: var(--stroke-accent);
            outline: none;
        }

        &[placeholder] {
            text-overflow: ellipsis;
        }
        &::-webkit-input-placeholder {
            /* WebKit browsers */
            text-overflow: ellipsis;
            color: var(--content-quaternary);
        }
        &::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: var(--content-quaternary);
            text-overflow: ellipsis;
        }
        &:-ms-input-placeholder {
            /* Internet Explorer 10+ */
            color: var(--content-quaternary);
            text-overflow: ellipsis;
        }
        &:-moz-placeholder {
            /* Firefox 18- */
            color: var(--content-quaternary);
            text-overflow: ellipsis;
        }
    }

    .InputBaseDisabledWrapper {
        .Input {
            border-color: $grey-light-1;
            background-color: $white;
            opacity: 0.34;

            &::-webkit-input-placeholder {
                /* WebKit browsers */
                color: var(--content-primary);
            }

            &:-moz-placeholder {
                /* Mozilla Firefox 4 to 18 */
                color: var(--content-primary);
            }

            &::-moz-placeholder {
                /* Mozilla Firefox 19+ */
                color: var(--content-primary);
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10+ */
                color: var(--content-primary);
            }
        }

        .InputWrapperInner {
            box-shadow: none;
        }

        .InputIconRight,
        .InputIconLeft {
            opacity: 0.34;
        }
    }

    .InputBaseWithErrorWrapper {
        .Input {
            border-color: var(--stroke-error);

            &:focus,
            &.active {
                border-color: var(--stroke-accent);
            }
        }
    }

    .InputBaseWrapperInnerInner {
        position: relative;
        flex: 1;
    }

    .InputBaseWrapper.with-icon-left {
        .InputBaseWrapperInner {
            position: relative;

            .Input {
                padding-left: 45px;
            }
        }
    }

    .InputBaseWrapper.with-icon-right {
        .InputBaseWrapperInner {
            position: relative;

            .Input {
                padding-right: 38px;
            }
        }
    }

    .InputIconRight,
    .InputIconLeft {
        position: absolute;
        top: 0;
        height: 100%;
        right: 0;
        padding: 0 8px;
        display: flex;
        align-items: center;

        > * {
            display: block;
        }

        .Button {
            display: block;
            padding: 10px 22px;

            &.plain:hover {
                background: none;
            }
        }

        .Icon:not(#hack) {
            width: 23px;
            height: 23px;
            color: var(--content-tertiary);
        }
    }

    .InputIconLeft {
        right: auto;
        left: 0;
        padding: 0 12px 0 16px;

        .Icon:not(#hack) {
            width: 16px;
            height: 16px;
        }
    }

    .Input.small + .InputIconRight,
    .Input.small + .InputIconLeft {
        .Button {
            padding-top: 10px;
            padding-bottom: 7px;
        }
    }

    .InputBaseWrapper.with-button-right {
        .InputBaseWrapperInner {
            display: flex;

            .Input {
                flex: 1;
            }

            .InputButtonRight {
                flex: 0;
            }

            .Input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right: none;
            }

            .InputButtonRight > button {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                height: 100%;
                padding-top: initial;
                padding-bottom: initial;
            }
        }
    }

    .InputBaseWrapper.with-icon-right.with-button-right {
        .Input {
            padding-right: 55px;
        }
    }

    // TODO - remove this
    &.DarkMode {
        .Input {
            border: 1px solid $grey-dark-3;
            background-color: $grey-dark-5;

            color: $white;

            & + span {
                .Icon {
                    color: $white !important;
                }
            }
        }
    }
}

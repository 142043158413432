@import 'core';

$colorScheme: (
    primary: var(--content-accent-secondary),
    secondary: var(--content-success),
    error: var(--content-error),
);

.AppCore {
    .RadioButton {
        .InputBaseWrapperInner {
            box-shadow: none;
        }

        &.Checkbox {
            > .InputBaseWrapperInner {
                line-height: 24px;
                height: 24px;

                .gfx {
                    color: var(--content-tertiary);
                    width: 24px;
                    height: 24px;
                }

                .CheckboxLabel {
                    padding-left: 18px;
                    line-height: 24px;
                }
            }
        }

        @each $variant, $color in $colorScheme {
            &.#{$variant} {
                input:checked {
                    & + .gfx {
                        color: $color;
                    }
                }
            }
        }

        input:checked:disabled,
        input:checked:disabled:hover,
        &:focus input:checked:disabled,
        &.active input:checked:disabled,
        &.active input:checked:disabled:hover,
        &.active:focus input:checked:disabled {
            pointer-events: none;
        }
    }
}

@import 'core';

.AppCore {
    @mixin make-spacing-l1($size, $unit, $responsivePrefix) {
        .m-#{$responsivePrefix}#{$size} {
            margin: #{$size}#{$unit} !important;
        }

        .p-#{$responsivePrefix}#{$size} {
            padding: #{$size}#{$unit} !important;
        }
    }

    @mixin make-spacing-l2($size, $unit, $responsivePrefix) {
        .mx-#{$responsivePrefix}#{$size} {
            margin-left: #{$size}#{$unit} !important;
            margin-right: #{$size}#{$unit} !important;
        }

        .my-#{$responsivePrefix}#{$size} {
            margin-top: #{$size}#{$unit} !important;
            margin-bottom: #{$size}#{$unit} !important;
        }

        .px-#{$responsivePrefix}#{$size} {
            padding-left: #{$size}#{$unit} !important;
            padding-right: #{$size}#{$unit} !important;
        }

        .py-#{$responsivePrefix}#{$size} {
            padding-top: #{$size}#{$unit} !important;
            padding-bottom: #{$size}#{$unit} !important;
        }
    }

    @mixin make-spacing-l3($size, $unit, $responsivePrefix) {
        .mt-#{$responsivePrefix}#{$size} {
            margin-top: #{$size}#{$unit} !important;
        }

        .mr-#{$responsivePrefix}#{$size} {
            margin-right: #{$size}#{$unit} !important;
        }

        .mb-#{$responsivePrefix}#{$size} {
            margin-bottom: #{$size}#{$unit} !important;
        }

        .ml-#{$responsivePrefix}#{$size} {
            margin-left: #{$size}#{$unit} !important;
        }

        .pt-#{$responsivePrefix}#{$size} {
            padding-top: #{$size}#{$unit} !important;
        }

        .pr-#{$responsivePrefix}#{$size} {
            padding-right: #{$size}#{$unit} !important;
        }

        .pb-#{$responsivePrefix}#{$size} {
            padding-bottom: #{$size}#{$unit} !important;
        }

        .pl-#{$responsivePrefix}#{$size} {
            padding-left: #{$size}#{$unit} !important;
        }
    }

    @include make-spacing-l1('auto', '', '');
    @include make-spacing-l2('auto', '', '');
    @include make-spacing-l3('auto', '', '');

    @for $i from 30 through 0 {
        @include make-spacing-l1($i, px, '');
    }

    @for $i from -1 through -30 {
        @include make-spacing-l1($i, px, '');
    }

    @for $i from 30 through 0 {
        @include make-spacing-l2($i, px, '');
    }

    @for $i from -1 through -30 {
        @include make-spacing-l2($i, px, '');
    }

    @for $i from 30 through 0 {
        @include make-spacing-l3($i, px, '');
    }

    @for $i from -1 through -30 {
        @include make-spacing-l3($i, px, '');
    }

    @include make-spacing-l1(32, px, '');
    @include make-spacing-l1(35, px, '');
    @include make-spacing-l1(36, px, '');
    @include make-spacing-l1(40, px, '');
    @include make-spacing-l1(45, px, '');
    @include make-spacing-l1(48, px, '');
    @include make-spacing-l1(50, px, '');
    @include make-spacing-l1(60, px, '');
    @include make-spacing-l1(70, px, '');

    @include make-spacing-l2(32, px, '');
    @include make-spacing-l2(35, px, '');
    @include make-spacing-l2(36, px, '');
    @include make-spacing-l2(40, px, '');
    @include make-spacing-l2(45, px, '');
    @include make-spacing-l2(48, px, '');
    @include make-spacing-l2(50, px, '');
    @include make-spacing-l2(60, px, '');
    @include make-spacing-l2(70, px, '');

    @include make-spacing-l3(32, px, '');
    @include make-spacing-l3(35, px, '');
    @include make-spacing-l3(36, px, '');
    @include make-spacing-l3(40, px, '');
    @include make-spacing-l3(45, px, '');
    @include make-spacing-l3(48, px, '');
    @include make-spacing-l3(50, px, '');
    @include make-spacing-l3(60, px, '');
    @include make-spacing-l3(70, px, '');

    @include media-breakpoint-down(none) {
        @include make-spacing-l1('auto', '', 'sm-');
        @include make-spacing-l2('auto', '', 'sm-');
        @include make-spacing-l3('auto', '', 'sm-');

        @for $i from 30 through 0 {
            @include make-spacing-l1($i, px, 'sm-');
        }

        @for $i from -1 through -30 {
            @include make-spacing-l1($i, px, 'sm-');
        }

        @for $i from 30 through 0 {
            @include make-spacing-l2($i, px, 'sm-');
        }

        @for $i from -1 through -30 {
            @include make-spacing-l2($i, px, 'sm-');
        }

        @for $i from 30 through 0 {
            @include make-spacing-l3($i, px, 'sm-');
        }

        @for $i from -1 through -30 {
            @include make-spacing-l3($i, px, 'sm-');
        }

        @include make-spacing-l1(35, px, 'sm-');
        @include make-spacing-l1(40, px, 'sm-');
        @include make-spacing-l1(45, px, 'sm-');
        @include make-spacing-l1(50, px, 'sm-');
        @include make-spacing-l1(60, px, 'sm-');
        @include make-spacing-l1(70, px, 'sm-');

        @include make-spacing-l2(35, px, 'sm-');
        @include make-spacing-l2(40, px, 'sm-');
        @include make-spacing-l2(45, px, 'sm-');
        @include make-spacing-l2(50, px, 'sm-');
        @include make-spacing-l2(60, px, 'sm-');
        @include make-spacing-l2(70, px, 'sm-');

        @include make-spacing-l3(35, px, 'sm-');
        @include make-spacing-l3(40, px, 'sm-');
        @include make-spacing-l3(45, px, 'sm-');
        @include make-spacing-l3(50, px, 'sm-');
        @include make-spacing-l3(60, px, 'sm-');
        @include make-spacing-l3(70, px, 'sm-');
    }
}

@import 'core';

.AppCore {
    .DateRangePicker {
        padding: 0 20px;

        > .Row:nth-child(2) {
            margin-top: -28px !important;
        }

        .DatePickerPrevNext {
            .DatePickerPrev {
                left: -20px;
            }

            .DatePickerNext {
                right: -20px;
            }
        }
    }
}

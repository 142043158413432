@import 'core';

.AppCore {
    .CurrentTimeLine {
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $color-error;
        z-index: 5;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 8px;
            height: 8px;
            background-color: $color-error;
            border-radius: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

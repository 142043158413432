@import 'core';

.AppCore {
    .RTView {
        display: flex;
        height: 100%;

        > div {
            display: inline-block;
            width: 100%;
            height: auto;
            outline: none;
            border: none;
            text-decoration: inherit;
        }

        &.HTMLEditorView {
            * {
                line-height: initial;
            }

            overflow-wrap: break-word;
        }
    }
}

@import 'core';

.AppCore {
    .SelectTreeHeader {
        margin-bottom: 0;
        padding: 8px 16px 16px 16px;
        .Pill {
            padding: 4px 8px;
        }
    }

    .SelectTreeInput {
        border: 1px solid var(--stroke-strong);
        box-shadow: $eleveted-surface-shadow;
        > select {
            display: none;
        }
    }

    .SelectTreeInputWithError {
        border-color: $color-error;
    }

    .SelectTree {
        max-height: 300px;
        overflow-y: auto;
        padding: 0 7px;
        border-top: 1px solid var(--stroke-strong);

        .Row:nth-child(2n + 1) {
            > .Col {
                background-color: var(--fill-elevated-primary);
            }
        }

        .SelectTreeBranch {
            display: flex;
            align-items: center;

            &.depth-0 {
                padding-left: 10px;
            }

            &.depth-1 {
                padding-left: 30px;
            }

            &.depth-2 {
                padding-left: 50px;
            }

            &.depth-3 {
                padding-left: 70px;
            }

            &.depth-4 {
                padding-left: 90x;
            }

            &.depth-5 {
                padding-left: 110px;
            }

            &.depth-6 {
                padding-left: 130px;
            }

            &.depth-7 {
                padding-left: 150px;
            }

            > .Checkbox,
            > span {
                flex: 1;
            }

            .Checkbox .InputBaseWrapperInner {
                height: auto;

                .CheckboxLabel {
                    display: block;
                    width: auto;
                    line-height: 19px;
                }
            }
        }
    }
    .SelectTreeBranchCollapseToggleHidden {
        visibility: hidden;
    }
    .SelectTreeBranchCollapseToggle {
        margin-right: 10px;
        width: 24px;
        height: 24px;
        border: 2px solid var(--content-tertiary);
        border-radius: 2px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        > .Icon {
            width: 100%;
            height: 100%;
            color: var(--content-tertiary);
        }

        &:hover {
            border-color: var(--content-primary);

            > .Icon {
                color: var(--content-primary);
            }
        }
    }
}
